import { Outlet } from "react-router-dom"
import "./ResetPasswordPage.scss"

export default function ForgotPasswordPage(): JSX.Element {
  return (
    <div className="forgot-pw-page">
      <div className="forgot-pw-page-title">Reset Password</div>
      <Outlet />
    </div>
  )
}
