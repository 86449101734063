import { useEffect, useMemo, useState } from "react"
import { Size, Type } from "@/enums/common.enum"
import { EditOutlined } from "@ant-design/icons"
import "./ProductItem.scss"
import { CustomCheckbox, NumberField } from "@/components"
import {
  LineItem,
  useDeletePurchaseMutation,
  useUpdateProductMutation
} from "@/services/apiDigifabster/quote"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { useSelector } from "react-redux"
import { ModelResponse } from "@/services/apiDigifabster/model"
import {
  BlockIcon,
  LoadingIcon,
  OrderReviewIcon,
  SuccessToastIcon,
  WarningDFMIcon
} from "@/assets"
import ViewerModal from "../ViewerModal/ViewerModal"
import { useAppLoading } from "@/hooks/useLoading"
import { debounce, priceDisplay } from "@/utils/functionHelper"
import {
  EModelAnalysisStatus,
  IMaterialConfig,
  IModelAnalysis,
  IProductStore
} from "@/store/product"
import { useCurrency } from "@/hooks/useCurrency"
import Skeleton from "react-loading-skeleton"
import {
  formatRangeDeliveryDate,
  getDiffDeliveryDays,
  getLatestDeliveryDate,
  getLatestLeadTimeForUser
} from "@/utils/timeFormatHelper"
import { IUserStore } from "@/store/user"
import {
  checkPlaceholderFile,
  getFileNameWithoutExtension,
  replacePostProductionTitle
} from "@/utils/stringHelper"
import { IPostProductionData } from "@/interfaces/editSpecifications.interface"
import { DATE_RANGE_FORMATS } from "@/constants/common.constant"
import { EManufacturingProcess } from "@/enums/editSpecifications.enum"
import { EOrderStatus } from "@/enums/quotesList.enum"
import DFMButton, { DFMType } from "../DFMButton/DFMButton"
import { MANUAL_REQUEST_MATERIAL_IDS } from "@/constants/order.constrant"

interface ProductItemProps {
  isSelected: boolean
  isProcessing: boolean
  onSelect: (selected: boolean) => void
  onEditItem: (id: number) => void
  item: LineItem
  isEditable?: boolean
  openDrawer?: (id: number) => void
  deliveryDays?: number
}

export const buttonStatusClass = {
  [EModelAnalysisStatus.LOADING]: "button-loading",
  [EModelAnalysisStatus.SUCCESS]: "button-success",
  [EModelAnalysisStatus.WARNING]: "button-warning",
  [EModelAnalysisStatus.ERROR]: "button-error"
}

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "corner-bottom-right-bevel-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessToastIcon,
    className: "corner-bottom-right-bevel-success"
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "corner-bottom-right-bevel-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Part Contains Manufacturing Issues",
    icon: BlockIcon,
    className: "corner-bottom-right-bevel-error"
  },
  [EModelAnalysisStatus.REVIEW]: {
    text: "Instant Price Unavailable",
    icon: "",
    className: "corner-bottom-right-bevel-review"
  }
}

function ProductItem(props: ProductItemProps) {
  const {
    isSelected,
    onSelect,
    onEditItem,
    isEditable = true,
    deliveryDays,
    openDrawer,
    isProcessing,
    item: {
      id,
      status,
      material_title,
      total_price,
      config,
      material,
      unit_price,
      amount,
      product,
      technology_title,
      self_notes
    }
  } = props
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""
  const [imageLoaded, setImageLoaded] = useState(false)
  const [postProduction, setPostProduction] = useState<IMaterialConfig[]>([])
  const isRequiresReview = technologies
    .find((e) => e.materials.find((m) => m.id === material))
    ?.materials.find((m) => m.id === material)
    ?.post_production?.some((n) =>
      config?.post_production?.some(
        (p) => p.uuid === n.uuid && n.note_for_user === "Requires manual review"
      )
    )

  const tech = useMemo(
    () => technologies.find((tech) => tech.title === technology_title),
    [technology_title, technologies]
  )

  const { currentQuote } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const currentModel = useSelector<RootState, ModelResponse | undefined>(
    (s) => s.quote.currentModels[product?.id || 0]
  )

  const modelAnalysis = useSelector<RootState, IModelAnalysis | undefined>(
    (s) => s.product.modelsAnalysis[product?.parent_model || product?.id || 0]
  )
  const { currency } = useCurrency()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [deletePurchase, { isLoading: isLoadingDelete }] =
    useDeletePurchaseMutation()
  const [updateProduct, { isLoading: isLoadingUpdate }] =
    useUpdateProductMutation()

  useAppLoading([isLoadingDelete])

  const handleDeleteItem = (id: number) => {
    deletePurchase({
      orderId: currentQuote?.id || 0,
      purchaseId: id
    })
  }

  useEffect(() => {
    const findPostProduction = technologies
      .find((tech) => tech.title === technology_title)
      ?.materials?.find((item) => item.id === material)

    if (findPostProduction) {
      const configUuids =
        config?.post_production?.map((item) => item.uuid) || []

      const filteredPostProduction = findPostProduction.post_production
        .filter((item) => configUuids.includes(item.uuid))
        .map((item) => {
          const configItem = config?.post_production?.find(
            (configItem) => configItem.uuid === item.uuid
          )
          return {
            ...item,
            quantity: configItem ? configItem.quantity : 0
          }
        })

      setPostProduction(filteredPostProduction)
    }
  }, [config?.post_production, material, technologies, technology_title])

  const isHidePrice = useMemo(() => {
    return (
      status === EOrderStatus.WAITING_FOR_REVIEW ||
      isProcessing ||
      modelAnalysis?.status === EModelAnalysisStatus.ERROR ||
      MANUAL_REQUEST_MATERIAL_IDS.includes(material)
    )
  }, [status, isProcessing, modelAnalysis, material])

  const immediatelyUpdateProduct = (
    value: number,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!currentQuote || !currentQuote.id || !id) return

    const configPayload = {
      layer_thickness: config?.layer_thickness?.uuid,
      filling: config?.filling?.uuid,
      color: config?.color?.uuid,
      lead_time: config?.lead_time?.uuid,
      post_production: config?.post_production?.map((item) => {
        return {
          uuid: item?.uuid,
          quantity: item?.quantity
        } as IPostProductionData
      })
    }
    updateProduct({
      orderId: currentQuote?.id,
      productId: id,
      arg: {
        count: value,
        config: configPayload,
        material_id: material,
        model_id: product?.id
      }
    })
    event?.target?.blur()
  }

  const debounceUpdateProduct = debounce((event, value) => {
    immediatelyUpdateProduct(event, value)
  }, 1000)

  const handleChangeQuanity = (
    value: number,
    event?: React.ChangeEvent<HTMLInputElement>,
    { isDebounce = true } = {}
  ) => {
    if (!currentQuote || !currentQuote.id || !id) return

    isDebounce
      ? debounceUpdateProduct(value, event)
      : immediatelyUpdateProduct(value, event)
  }

  const stopCollapseEvent = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const statusTag = useMemo(() => {
    if (isRequiresReview) return EModelAnalysisStatus.WARNING
    return status === EOrderStatus.WAITING_FOR_REVIEW
      ? checkPlaceholderFile(self_notes || "")
        ? EModelAnalysisStatus.REVIEW
        : EModelAnalysisStatus.ERROR
      : modelAnalysis?.status || EModelAnalysisStatus.LOADING
  }, [
    modelAnalysis,
    isRequiresReview,
    self_notes,
    status
  ]) as EModelAnalysisStatus

  const preventPointerCursor = // prevent pointer cursor when the item is not editable and in loading state
    !isEditable || isLoadingUpdate ? " uneditable" : ""

  const getStatusDFMButton: Record<EModelAnalysisStatus, DFMType> = {
    [EModelAnalysisStatus.SUCCESS]: DFMType.SUCCESS,
    [EModelAnalysisStatus.WARNING]: DFMType.WARNING,
    [EModelAnalysisStatus.ERROR]: DFMType.ERROR,
    [EModelAnalysisStatus.LOADING]: DFMType.DISABLE,
    [EModelAnalysisStatus.REVIEW]: DFMType.REVIEW
  }

  const isShowIcon = useMemo(() => {
    return tagStatusClass[
      isProcessing ? EModelAnalysisStatus.LOADING : statusTag
    ]?.icon
  }, [isProcessing, statusTag])

  const renderPostProductionGroup = (
    groupTitles: string[],
    fallbackText: string
  ) => {
    const filteredItems = postProduction.filter((item) =>
      groupTitles.includes(item?.group_title as string)
    )

    return filteredItems.length > 0
      ? filteredItems.map((item, index) => (
          <div key={`${index} post`}>
            {replacePostProductionTitle(item.title, country)}{" "}
            {item.countable && `(${item.quantity})`}
          </div>
        ))
      : fallbackText
  }

  return (
    <>
      <div
        className={`col${preventPointerCursor} ${isSelected && "col-selected"}`}
        onClick={() => {
          if (!isEditable || isLoadingUpdate) return // prevent open drawer when the item is not editable and in loading state
          openDrawer?.(id)
        }}
      >
        <div className={`product-tag`}>
          <p>
            {isShowIcon ? (
              <img
                width={16}
                height={16}
                src={
                  isProcessing
                    ? tagStatusClass[EModelAnalysisStatus.LOADING]?.icon
                    : tagStatusClass[statusTag]?.icon
                }
                alt=""
              />
            ) : null}
            <span>
              {isRequiresReview
                ? "Requires Manual Review"
                : isProcessing
                ? tagStatusClass[EModelAnalysisStatus.LOADING]?.text
                : tagStatusClass[statusTag]?.text}
            </span>
          </p>

          <div
            className={`corner-bottom-right-bevel  ${
              isProcessing
                ? tagStatusClass[EModelAnalysisStatus.LOADING]?.className
                : tagStatusClass[statusTag].className
            }`}
          ></div>
        </div>

        {/* Floating edit button */}
        <div className="edit-container">
          {isEditable && !isLoadingUpdate ? (
            <EditOutlined className="edit-btn" />
          ) : (
            <div className="icon-loading">
              <img src={LoadingIcon} />
            </div>
          )}
        </div>
        <div className={`container${preventPointerCursor}`}>
          <div className="col-1">
            <CustomCheckbox
              style={isSelected ? { visibility: "visible" } : {}}
              checked={isSelected}
              size={Size.MEDIUM}
              onChange={(e) => onSelect(e.target.checked)}
              onClick={(e) => stopCollapseEvent(e)}
            />
            <div
              className={`product-viewer${preventPointerCursor}`}
              onClick={(e) => {
                if (
                  statusTag === EModelAnalysisStatus.REVIEW ||
                  !isEditable ||
                  isLoadingUpdate
                )
                  return
                setIsModalOpen(true)
                stopCollapseEvent(e)
              }}
            >
              {currentModel?.thumb_120x120 && (
                <img
                  width={120}
                  height={120}
                  src={currentModel?.thumb_120x120}
                  alt={currentModel?.title}
                  style={{ display: imageLoaded ? undefined : "none" }}
                  onLoad={() => {
                    setImageLoaded(true)
                  }}
                />
              )}

              {!imageLoaded && <Skeleton width={120} height={120} />}
            </div>

            {currentModel ? (
              <div className="col-2-text">
                <div className="col-2-text-filename">
                  {checkPlaceholderFile(self_notes || "") &&
                  status === EOrderStatus.WAITING_FOR_REVIEW
                    ? getFileNameWithoutExtension(currentModel?.title)
                    : currentModel?.title}
                </div>
                <div className="col-2-size">
                  {currentModel?.size && (
                    <>
                      {statusTag !== EModelAnalysisStatus.REVIEW ? (
                        <p>
                          {currentModel?.units === "mm"
                            ? Math.round(currentModel?.size?.x || 0)
                            : currentModel?.size?.x?.toFixed(2) || 0}{" "}
                          x{" "}
                          {currentModel?.units === "mm"
                            ? Math.round(currentModel?.size?.y || 0)
                            : currentModel?.size?.y?.toFixed(2) || 0}{" "}
                          x{" "}
                          {currentModel?.units === "mm"
                            ? Math.round(currentModel?.size?.z || 0)
                            : currentModel?.size?.z?.toFixed(2) || 0}{" "}
                          {currentModel?.units || ""}
                        </p>
                      ) : (
                        <p>
                          Our team will contact you to finalize this quote after
                          you request a review
                        </p>
                      )}
                    </>
                  )}
                </div>
                {/* <div className="col-2-volume">
                  <p>
                    {currentModel?.volume?.toFixed(2)}{" "}
                    {currentModel?.units ? currentModel?.units + "3" : ""}
                  </p>
                </div> */}
                {statusTag ===
                EModelAnalysisStatus.REVIEW ? null : statusTag ===
                  EModelAnalysisStatus.LOADING ? (
                  <div>
                    <DFMButton
                      status={EModelAnalysisStatus.LOADING}
                      onClick={(e) => {
                        setIsModalOpen(true)
                        stopCollapseEvent(e)
                      }}
                    >
                      View DFM Analysis
                    </DFMButton>
                  </div>
                ) : (
                  <div>
                    <DFMButton
                      status={statusTag}
                      disabled={!isEditable || isLoadingUpdate}
                      customType={getStatusDFMButton[statusTag]}
                      onClick={(e) => {
                        setIsModalOpen(true)
                        stopCollapseEvent(e)
                      }}
                    >
                      View DFM Analysis
                    </DFMButton>
                  </div>
                )}
              </div>
            ) : (
              <div className="col-2-text">
                <div className="col-2-text-filename">
                  {checkPlaceholderFile(self_notes || "") &&
                  status === EOrderStatus.WAITING_FOR_REVIEW
                    ? getFileNameWithoutExtension(product?.title)
                    : product?.title}
                </div>
                <div className="col-2-size">
                  <p>
                    <Skeleton width={120} height={20} />
                  </p>
                </div>
                <div className="col-2-volume">
                  <p>
                    <Skeleton width={60} height={20} />
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="col-3">
            <p>{tech?.title || ""}</p>
            <p>
              {material_title || ""}, {config?.color?.name_for_user || ""}
            </p>
            <p>{config?.layer_thickness?.name_for_user || ""} um</p>
            <p>
              {config?.filling?.name_for_user ||
                config?.filling?.value?.toFixed(1) + " percent" ||
                ""}
            </p>
            <p>{}</p>
          </div>
          <div className="col-4">
            <p>
              {renderPostProductionGroup(
                ["", "Post Production"],
                "Standard Finish"
              )}
            </p>
            <p>
              {renderPostProductionGroup(
                ["Inspection Criteria"],
                "Visual Inspection"
              )}
            </p>
            <p>{renderPostProductionGroup(["Other"], "")}</p>
            {/* <p>Visual Inspection</p>
            {config?.post_production?.length > 0  && (
              <>
                {config?.post_production?.map((item, index) => (
                  <div key={`${index} post`}>
                    <p>
                      {replacePostProductionTitle(item.title, country)}{" "}
                      {item.countable && `(${item.quantity})`}
                      {item.group_title}
                    </p>
                  </div>
                ))}
              </>
            )} */}
          </div>
          <div className="col-5" onClick={(e) => e.stopPropagation()}>
            <NumberField
              type={Type.NEUTRAL}
              isDebounce={false}
              value={amount}
              setValue={handleChangeQuanity}
              disable={isLoadingUpdate || !isEditable} // disable input when the item is in loading state or not editable
            />
          </div>
          <div className={`col-6${preventPointerCursor}`}>
            <div className="col-6-text">
              <p>
                {isHidePrice || statusTag === EModelAnalysisStatus.REVIEW ? (
                  <>Requires Review</>
                ) : (
                  <>
                    {currency}
                    {priceDisplay(total_price || 0)}
                  </>
                )}
              </p>
              <p>
                {isHidePrice || statusTag === EModelAnalysisStatus.REVIEW ? (
                  <></>
                ) : (
                  <>
                    {currency}
                    {priceDisplay(unit_price || 0)} / part
                  </>
                )}
              </p>
            </div>
            {/* {isEditable && !isLoadingUpdate ? ( // enable edit button only when the item is not in loading state
              <div className="col-6-action">
                <Button
                  hierarchy={ButtonHierarchy.LINK}
                  onClick={() => onEditItem(id)}
                  customSize={Size.MEDIUM}
                  customClassName="edit-btn"
                >
                  <EditOutlined /> Edit Configuration
                </Button>
                <Button
                  hierarchy={ButtonHierarchy.LINK}
                  onClick={(e) => {
                    handleDeleteItem(id)
                    stopCollapseEvent(e)
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            ) : (
              <div className="icon-loading">
                <img src={LoadingIcon} />
              </div>
            )} */}
          </div>
        </div>
        <div className="date-delivery">
          <div />
          <div />
          <div />
          <div className="date-delivery-container">
            <p>
              {formatRangeDeliveryDate(
                getDiffDeliveryDays(
                  getLatestLeadTimeForUser(
                    currentQuote?.products as unknown as LineItem[]
                  )
                ),
                getLatestDeliveryDate(
                  currentQuote?.products as unknown as LineItem[]
                ),
                new Date(),
                {
                  from: DATE_RANGE_FORMATS.longWithComma,
                  to: DATE_RANGE_FORMATS.longWithComma
                }
              )}
            </p>
          </div>
        </div>
        {/* <CustomBadge
          customClassName="date-delivery"
          // content={formatRangeDeliveryDate(
          //   deliveryDays || config.lead_time.days,
          //   0,
          //   new Date(),
          //   { from: DATE_RANGE_FORMATS.comma, to: DATE_RANGE_FORMATS.comma }
          // )}
          content={formatRangeDeliveryDate(
            getDiffDeliveryDays(
              getLatestLeadTimeForUser(
                currentQuote?.products as unknown as LineItem[]
              )
            ),
            getLatestDeliveryDate(
              currentQuote?.products as unknown as LineItem[]
            ),
            new Date(),
            { from: DATE_RANGE_FORMATS.comma, to: DATE_RANGE_FORMATS.comma }
          )}
          color={BadgeColor.SUCCESS}
          type={BadgeType.TONAL}
          size={Size.MEDIUM}
        /> */}
      </div>

      <ViewerModal
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        currentPart={currentModel}
        loading={false}
        statusTag={statusTag}
        analysis={modelAnalysis}
        technology={tech?.id as EManufacturingProcess}
        product={product}
        material={material}
        config={config}
        isProcessing={isProcessing}
      />
    </>
  )
}

export default ProductItem
