import "./DFMCheck.scss"
import "@/assets/scss/_styles.scss"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { tagStatusClass } from "../ViewerModal/ViewerModal"
import { EModelAnalysisStatus, IModelAnalysis } from "@/store/product"
import { useMemo, useState } from "react"
import { ICheckedDFM, ITechMap } from "@/interfaces/order.interface"
import { EManufacturingProcess } from "@/enums/editSpecifications.enum"
import ToolTipDFM from "../ToolTipDFM/ToolTipDFM"

interface DFMCheckProps {
  data: ITechMap
  analysis?: IModelAnalysis
  technology: EManufacturingProcess
}

const tooltips: { [key: number]: string } = {
  [EManufacturingProcess.SLS]: `To reduce warping, we recommend using consistent wall thicknesses to minimize thermal variation, and incorporating support structures or ribbing to enhance stability. If your part is susceptible to warping, we recommend either SLA or FDM technology.`,
  [EManufacturingProcess.MJF]: `To reduce warping, we recommend using consistent wall thicknesses to minimize thermal variation, and incorporating support structures or ribbing to enhance stability. If your part is susceptible to warping, we recommend either SLS, SLA, or FDM technology.`
}

function DFMCheck({ data, analysis, technology }: DFMCheckProps) {
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null)

  const wallThicknessStatus = useMemo(
    () => analysis?.wallThicknessStatus,
    [analysis]
  )
  const handleTooltipClick = (index: number) => {
    setVisibleIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const getToolTip = (technology: EManufacturingProcess) => {
    return tooltips[Number(technology)] || ""
  }

  const renderCheckedInfo: ICheckedDFM[] = [
    {
      title: "Part Size",
      value: analysis?.errorPartSize?.length
        ? `${analysis?.errorPartSize} ${
            analysis?.errorPartSize?.includes("too small") ? "" : data?.partSize
          }`
        : "Part fits the build volume of the selected technology.",
      isPre: true,
      status: analysis?.errorPartSize?.length
        ? EModelAnalysisStatus.ERROR
        : EModelAnalysisStatus.SUCCESS
    },
    {
      title: "Wall Thickness",
      value:
        `Minimum recommended wall thickness is ${data?.thickness} mm.` || "",
      isPre: true,
      status:
        wallThicknessStatus === EModelAnalysisStatus.SUCCESS
          ? EModelAnalysisStatus.SUCCESS
          : EModelAnalysisStatus.WARNING
    },
    {
      title: "Trapped Volume",
      value:
        data?.trappedVolume || "The part cannot contain any trapped volumes.",
      textTooltip:
        "Parts with trapped volumes result in material being unable to be removed. We recommend to include a drain hole of at least 3 mm.",
      isPre: false
    },
    {
      title: "Hole Size",
      value: `Minimum hole diameter is ${data?.holeSize} mm.` || "",
      textTooltip:
        "Minimum hole size is essential for ensuring holes print accurately and remain functional; holes smaller than the printer’s resolution may close up or distort. For fine holes, we recommend SLA technology.",
      isPre: false
    },
    {
      title: "Minimum Detail Size",
      value: `Minimum detail size is ${data?.minimumDetailSize} mm.` || "",
      textTooltip: `Minimum detail size is critical to ensure features print clearly and remain durable; features smaller than the printer's resolution may be lost or fragile. If your part has fine details, we recommend SLA or SLS technology.`,
      isPre: false
    },
    {
      title: "Warping & Shrinkage",
      value: data?.warpingAndShrinkage || "",
      textTooltip: getToolTip(technology),
      isPre: false
    },
    {
      title: "Complexity",
      value: data?.complexity || "",
      textTooltip: data?.complexity
        ? `Organic shapes and intricate details may not resolve in FDM due to the printer's layer-based resolution limitations, which can cause fine details to appear less precise or even unrecognizable. We recommend laser based technologies such as SLA or SLS technologies for complex, organic models.`
        : "",
      isPre: false
    }
  ]

  const handleVisibleChange = () => {
    setVisibleIndex(null)
  }

  return (
    <div className="dfm-check-container">
      <div className="dfm-check-pre">
        <p className="dfm-check-pre-title">Pre-Manufacturing Check</p>
        {renderCheckedInfo
          .filter((item) => item.isPre)
          .map((item, index) => (
            <div className="dfm-check-pre-item" key={index}>
              <img
                src={
                  tagStatusClass[item?.status || EModelAnalysisStatus.ERROR]
                    .icon
                }
                width={16}
                height={16}
                alt=""
              />
              <div className="dfm-check-pre-item-text">
                <h5>{item.title}</h5>
                {item.status !== EModelAnalysisStatus.SUCCESS && (
                  <p
                    className={
                      item.title === "Wall Thickness" ? "no-style" : ""
                    }
                  >
                    {item.value}
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="dfm-check-other">
        <div className="dfm-check-other-header">
          <ExclamationCircleOutlined
            style={{ color: "var(--bg-gray-stronger)", paddingTop: 3 }}
          />
          <p>
            The following features checks cannot be determined automatically.
            See full{" "}
            <a
              className="text-navigate-link"
              target="_blank"
              href="https://forgelabs.com/3d-printing-design-guide/"
            >
              design guidelines
            </a>
          </p>
        </div>
        <div className="dfm-check-other-content">
          {renderCheckedInfo
            .filter((item) => !item.isPre)
            .map((item, index) => (
              <div className="dfm-check-other-content-detail" key={index}>
                {item.value && (
                  <div className="dfm-check-other-content-detail-text">
                    <div className="dfm-check-other-content-detail-text-header">
                      <h5>{item.title}</h5>
                      {item.textTooltip && (
                        <ToolTipDFM
                          title={item.textTooltip}
                          isOpen={visibleIndex === index}
                          isHover={visibleIndex === index}
                          onVisibleChange={handleVisibleChange}
                          onHoverToolTip={() => handleTooltipClick(index)}
                        ></ToolTipDFM>
                      )}
                    </div>
                    <p>{item.value}</p>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default DFMCheck
