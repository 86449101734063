import { arrowDownUnitIcon, arrowDownUnitSuccessIcon } from "@/assets"
import { Button, RadioButton } from "@/components"
import { UNIT_OPTIONS } from "@/constants/unit.constant"
import { Size, ButtonHierarchy, Type } from "@/enums/common.enum"
import { Popover, Radio } from "antd"
import "./PopOverSelect.scss"
import { EUnit } from "@/enums/unit.enum"
import { EModelAnalysisStatus } from "@/store/product"
import { useState } from "react"

interface IPopOverProps {
  selectedUnit: EUnit
  handleResize?: (unit: EUnit) => void
  status?: EModelAnalysisStatus
}

function PopOverSelect({ selectedUnit, handleResize, status }: IPopOverProps) {
  const [unitSelected, setUnitSelected] = useState<EUnit>(selectedUnit)
  const unit = UNIT_OPTIONS.find((item) => item.id === selectedUnit)?.title

  const handleSelected = (e: any) => {
    setUnitSelected(e.target.value)
  }

  const handleConfirm = () => {
    handleResize?.(unitSelected)
  }

  const handlePopoverVisibleChange = (visible: boolean) => {
    if (visible) {
      setUnitSelected(selectedUnit)
    }
  }

  const content = (
    <div className="popover">
      <div className="popover-box">
        {UNIT_OPTIONS.map((item) => (
          <div className="popover-box-select">
            <div className="popover-box-select-item">
              <Radio.Group onChange={handleSelected} value={unitSelected}>
                <RadioButton value={item.id}>{item.title}</RadioButton>
              </Radio.Group>
            </div>
          </div>
        ))}
      </div>
      <div className="popover-btn">
        <Button
          // disabled={status === EModelAnalysisStatus.ERROR}
          onClick={handleConfirm}
          customSize={Size.EXTRA_SMALL}
        >
          Confirm
        </Button>
      </div>
    </div>
  )

  return (
    <Popover
      content={content}
      trigger={"click"}
      placement="bottomLeft"
      arrow={false}
      onOpenChange={handlePopoverVisibleChange}
    >
      <Button
        customSize={Size.EXTRA_SMALL}
        hierarchy={ButtonHierarchy.LINK}
        customType={
          status !== EModelAnalysisStatus.ERROR ? Type.NEUTRAL : Type.PRIMARY
        }
      >
        {unit}
        <img
          src={
            status !== EModelAnalysisStatus.ERROR
              ? arrowDownUnitSuccessIcon
              : arrowDownUnitIcon
          }
          alt=""
        />
      </Button>
    </Popover>
  )
}

export default PopOverSelect
