import {
  ActionReducerMapBuilder,
  createSlice,
  isAnyOf,
  PayloadAction
} from "@reduxjs/toolkit"
import {
  LineItem,
  OrderDetailResponse,
  Product,
  QuoteResponse,
  quoteService
} from "@/services/apiDigifabster/quote"
import {
  ModelResponse,
  PartialCncFeature,
  Cncfeature,
  modelService,
  ResizeModelResponse
} from "@/services/apiDigifabster/model"
import {
  IGetInvoiceResponse,
  orderService
} from "@/services/apiDigifabster/order"
import { UploadFile } from "antd"
import { EModelAnalysisStatus, IModelAnalysis } from "../product"
export interface IQuoteStore {
  currentQuote?: QuoteResponse
  quoteDetail?: OrderDetailResponse
  selectedProduct?: Product
  currentPriceProduct?: {
    price_per_part: number | undefined
    total_price: number | undefined
    errorAnalysisText?: string
  }
  currentModels: Record<number, ModelResponse>
  invoiceDetail: IGetInvoiceResponse
  isUploadingFile: boolean

  doneKeysCheckout: number[]
  clickableKeysCheckout: number[]
  newQuoteUploaded: UploadFile[]

  isSidebarOpen: boolean
  placedAt: string
  modelsAnalysis: Record<number, IModelAnalysis>
}
const initialState: IQuoteStore = {
  currentQuote: {} as QuoteResponse,
  quoteDetail: undefined,
  invoiceDetail: {} as IGetInvoiceResponse,
  currentModels: {},
  isUploadingFile: false,
  doneKeysCheckout: [],
  clickableKeysCheckout: [1],
  newQuoteUploaded: [],
  isSidebarOpen: true,
  placedAt: "",
  modelsAnalysis: {}
}
const quoteSlice = createSlice({
  name: "quote",
  initialState: initialState,
  reducers: {
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
    setIsUploading: (state, action: PayloadAction<boolean>) => {
      state.isUploadingFile = action.payload
    },
    cleanState: (state) => {
      state.quoteDetail = undefined
      state.currentQuote = undefined
      return state
    },
    addQuoteData: (state, { payload }: PayloadAction<QuoteResponse>) => {
      state.currentQuote = { ...state.currentQuote, ...payload }
    },
    setQuoteData: (state, { payload }: PayloadAction<QuoteResponse>) => {
      state.currentQuote = payload
    },
    setQuoteDetailData: (
      state,
      { payload }: PayloadAction<OrderDetailResponse>
    ) => {
      state.quoteDetail = payload
    },
    setEditProduct: (state, { payload }: PayloadAction<Product>) => {
      state.selectedProduct = payload
      state.currentPriceProduct = {
        price_per_part: payload.price_per_part,
        total_price: payload.total_price
      }
    },
    setQuoteShippingAndTaxPrice: (
      state,
      { payload }: PayloadAction<{ shippingPrice: number; taxRate?: number }>
    ) => {
      if (!state.quoteDetail) return
      const { models_price, price_corrections_cost } = state.quoteDetail

      let taxRate = 0
      let taxValue = 0
      if (payload.taxRate) {
        state.quoteDetail.tax = payload.taxRate
        taxRate = payload.taxRate
      } else {
        taxRate = Number(state.quoteDetail.tax)
      }

      state.quoteDetail.delivery_price = payload.shippingPrice

      let _totalPrice = Number(models_price) + Number(price_corrections_cost)
      taxValue = _totalPrice * taxRate
      state.quoteDetail.tax_value = taxValue
      _totalPrice = _totalPrice + taxValue
      state.quoteDetail.total_price = _totalPrice + payload.shippingPrice
    },
    setDoneKeysCheckout: (state, { payload }: PayloadAction<number[]>) => {
      state.doneKeysCheckout = payload
    },
    removeDonKeysCheckout: (state, { payload }: PayloadAction<number>) => {
      state.doneKeysCheckout = state.doneKeysCheckout.filter(
        (e) => e !== payload
      )
    },
    setClickableKeysCheckout: (state, { payload }: PayloadAction<number[]>) => {
      state.clickableKeysCheckout = payload
    },
    updateStatusInvoice: (state, action: PayloadAction<string>) => {
      if (state.invoiceDetail && state.invoiceDetail.order) {
        state.invoiceDetail.order.status_display = action.payload
      }
    },
    setNewQuoteUploaded: (state, { payload }: PayloadAction<UploadFile[]>) => {
      state.newQuoteUploaded = payload
    },
    fileToProduct: (state, { payload }: PayloadAction<UploadFile[]>) => {
      if (!state.quoteDetail || !state.quoteDetail.id) return

      const items = state.quoteDetail.line_items
      const products = payload.map<LineItem>(
        (e) =>
          ({
            local_file: {
              name: e.name,
              id: e.uid,
              status: e.status === "error" ? "error" : "uploading",
              error: e.error,
              modelId: e?.response?.data?.object_models?.[0]?.id,
              file: e
            }
          } as LineItem)
      )

      state.quoteDetail.line_items = [...items, ...products]
    },
    fileToProductUpdate: (
      state,
      { payload }: PayloadAction<Record<string, UploadFile>>
    ) => {
      if (!state.quoteDetail || !state.quoteDetail.id) return

      const products = state.quoteDetail.line_items.map<LineItem>((e) => {
        const item = payload[String(e.local_file?.id)]
        if (!item) return e

        return {
          local_file: {
            name: item.name,
            id: item.uid,
            status: item.status === "error" ? "error" : "uploading",
            error: item.error,
            modelId: item?.response?.data?.object_models?.[0]?.id,
            file: item
          }
        } as LineItem
      })

      state.quoteDetail.line_items = products
    },
    fileToProductUpdateExcludeError: (
      state,
      { payload }: PayloadAction<Record<string, UploadFile>>
    ) => {
      if (!state.quoteDetail || !state.quoteDetail.id) return

      const products = state.quoteDetail.line_items.map<LineItem>((e) => {
        const item = payload[String(e.local_file?.id)]
        if (!item) return e

        return {
          local_file: {
            name: item.name,
            id: item.uid,
            status: item.status === "error" ? "error" : "uploading",
            error: item.error,
            modelId: item?.response?.data?.object_models?.[0]?.id,
            file: item
          }
        } as LineItem
      })

      state.quoteDetail.line_items = products.filter(
        (e) => !e.local_file?.error
      )
    },
    fileToProductDelete: (state, { payload }: PayloadAction<string>) => {
      if (!state.quoteDetail || !state.quoteDetail.id) return
      const products = state.quoteDetail.line_items.filter(
        (e) => !(e.local_file && e.local_file.id === payload)
      )

      state.quoteDetail.line_items = products
    }
  },
  extraReducers: (builders: ActionReducerMapBuilder<IQuoteStore>) => {
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.getQuote.matchFulfilled),
      (state, { payload }) => {
        state.currentQuote = payload
        state.quoteDetail = {} as OrderDetailResponse
        state.quoteDetail.id = payload.id
        state.quoteDetail.line_items = []
      }
    )
    builders.addMatcher(
      isAnyOf(modelService.endpoints.resizeModel.matchFulfilled),
      (state, { payload, meta }) => {
        const modelAfterResize = payload?.data?.order?.products.find(
          (item) => item.id === payload.data.purchase_id
        )
        if (modelAfterResize) {
          state.currentModels[meta.arg.originalArgs.key || 0] = {
            ...state.currentModels[meta.arg.originalArgs.key || 0],
            parent_model: modelAfterResize.parent_model_id,
            id: modelAfterResize.model_id,
            title: modelAfterResize.model_title,
            units: meta.arg.originalArgs.arg.units
          }
        }
        state.currentQuote = payload.data.order
        const currentProduct =
          state.quoteDetail?.line_items.filter((e) => e.local_file) || []
        const products = [...currentProduct, ...payload.data.order.products]

        const updateProduct = {
          ...state.quoteDetail,
          line_items: (products as unknown as LineItem[]) || ([] as LineItem[])
        } as OrderDetailResponse
        state.quoteDetail = updateProduct
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.getQuoteDetail.matchFulfilled),
      (state, { payload }) => {
        const newProducts = [...payload.line_items]?.sort((a, b) => {
          const dateA = new Date(a?.product?.date_created).getTime()
          const dateB = new Date(b?.product?.date_created).getTime()
          return dateA - dateB
        })

        const newQuoteDetail = {
          ...payload,
          line_items: [...newProducts]
        }

        state.quoteDetail = newQuoteDetail
        payload.line_items.forEach((e) => {
          const product = e?.product

          const cncFeatures: PartialCncFeature[] =
            product?.cnc_features.map((feature) => ({
              type: feature.type,
              type_display: feature.type_display,
              shape_type: feature.shape_type,
              shape_type_display: feature.shape_type_display
            })) || []

          const newModel = {
            id: e?.product?.id,
            title: product?.title,
            units: product?.units,
            volume: product?.volume,
            cnc_features: cncFeatures as Cncfeature[],
            thumb: product?.thumb,
            thumb_120x120: product?.thumb_120x120,
            thumb_300x300: product?.thumb_300x300,
            thumb_status: product?.thumb_status,
            file_model_viewer_url: product?.file_model_viewer_url,
            parent_model: product?.parent_model,
            geometry_type: product?.geometry_type,
            size: product?.size,
            child_models: product?.child_models,
            is_processable: product?.is_processable
          }
          state.currentModels[newModel.id] = newModel
        })
        state.placedAt = payload.placed_at
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.purchaseProduct.matchFulfilled),
      (state, { payload }) => {
        state.currentQuote = payload.order
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.updateProduct.matchFulfilled),
      (state, { payload }) => {
        const { order } = payload

        const newProducts = [...order.products] as unknown as LineItem[]

        const localFiles = state.quoteDetail?.line_items?.filter(
          (e) =>
            e.local_file &&
            !order.products.find(
              (p) =>
                p.model_id === e.local_file?.modelId ||
                p.parent_model_id === e.local_file?.modelId
            )
        )

        const newQuoteDetail = {
          ...state.quoteDetail,
          line_items: [...newProducts, ...(localFiles || [])]
        } as OrderDetailResponse
        state.quoteDetail = newQuoteDetail
        state.currentQuote = payload.order
      }
    )

    builders.addMatcher(
      isAnyOf(quoteService.endpoints.deletePurchase.matchFulfilled),
      (state, { payload }) => {
        state.currentQuote = payload.order

        const currentProduct =
          state.quoteDetail?.line_items.filter((e) => e.local_file) || []
        const products = [...currentProduct, ...payload.order.products]

        const updateProduct = {
          ...state.quoteDetail,
          models_naked_price: Number(payload.order.models_naked_price) || 0,
          line_items: (products as unknown as LineItem[]) || ([] as LineItem[])
        } as OrderDetailResponse
        state.quoteDetail = updateProduct
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.getPriceProduct.matchFulfilled),
      (state, { payload }) => {
        const { prices } = payload

        const pricePerItem = prices[0].priority_prices.length
          ? prices[0].priority_prices[0].price_info.price_per_item
          : undefined
        const total = prices[0].priority_prices.length
          ? prices[0].priority_prices[0].price_info.total
          : undefined
        const errAnalysis = payload.analysing_errors.length
        state.currentPriceProduct = {
          price_per_part: Number(pricePerItem),
          total_price: total,
          errorAnalysisText: errAnalysis ? payload.analysing_errors[0].text : ""
        }
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.uploadDrawings.matchFulfilled),
      (state, { payload, meta }) => {
        const { purchase_id } = meta.arg.originalArgs
        const product = state.currentQuote?.products?.find(
          (e) => e.id === purchase_id
        )
        const currentFiles = product?.drawing_files || []
        const drawing_files = currentFiles.concat(payload)
        const newProduct = { ...product, drawing_files } as Product
        const newProducts = {
          ...state.currentQuote,
          products: state.currentQuote?.products.map((e) =>
            e.id === purchase_id ? newProduct : e
          )
        } as QuoteResponse
        state.currentQuote = newProducts
      }
    )
    builders.addMatcher(
      isAnyOf(quoteService.endpoints.deleteDrawings.matchFulfilled),
      (state, { meta }) => {
        const { drawings_id, purchase_id } = meta.arg.originalArgs
        const product = state.currentQuote?.products.find(
          (e) => e.id === purchase_id
        )
        const drawing_files = product?.drawing_files.filter(
          (e: any) => e.id !== drawings_id
        )
        const newProduct = { ...product, drawing_files } as Product
        const newProducts = {
          ...state.currentQuote,
          products: state.currentQuote?.products.map((e) =>
            e.id === purchase_id ? newProduct : e
          )
        } as QuoteResponse
        state.currentQuote = newProducts
      }
    )
    builders.addMatcher(
      isAnyOf(
        orderService.endpoints.getInvoice.matchFulfilled,
        orderService.endpoints.updateInvoice.matchFulfilled
      ),
      (state, { payload }) => {
        state.invoiceDetail = payload
      }
    )
  }
})
export const {
  setQuoteData,
  addQuoteData,
  cleanState,
  setIsUploading,
  setEditProduct,
  updateStatusInvoice,
  setDoneKeysCheckout,
  removeDonKeysCheckout,
  setClickableKeysCheckout,
  setQuoteShippingAndTaxPrice,
  setNewQuoteUploaded,
  setIsSidebarOpen,
  fileToProduct,
  fileToProductUpdate,
  fileToProductUpdateExcludeError,
  fileToProductDelete
} = quoteSlice.actions
export const quoteReducer = quoteSlice.reducer
