import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import OrderReviewItem from "../OrderReviewItem/OrderReviewItem"
import "./OrderReview.scss"
import { useNavigate } from "react-router-dom"
import { Button, TextField } from "@/components"
import { Controller, useForm } from "react-hook-form"
import { Form } from "antd"
import { INotesForm } from "@/interfaces/notes.interface"
import { Type } from "@/enums/common.enum"
import { NotesIcon } from "@/assets"
import {
  getLatestDeliveryDate,
  getLatestLeadTimeForUser
} from "@/utils/timeFormatHelper"

interface IOrderReviewProps {
  onSubmit: (data: INotesForm) => void
}

const OrderReview: React.FC<IOrderReviewProps> = ({ onSubmit }) => {
  const { currentQuote, quoteDetail } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )

  const navigate = useNavigate()

  const { control, handleSubmit } = useForm<INotesForm>({
    defaultValues: {
      notes: ""
    }
  })

  useEffect(() => {
    if (currentQuote?.products?.length === 0)
      navigate(`/new-quote/specification/${currentQuote?.id}`)
  }, [currentQuote])

  const getConfig = (id: number) => {
    return quoteDetail?.line_items?.find((item) => item.id === id)?.config
  }

  if (!currentQuote?.products) return <></>
  return (
    <>
      <div className="order-review">
        <div className="order-review-test">
          <p>MODEL</p>
          <p>SPECIFICATIONS</p>
          <p>POST PRODUCTION</p>
          <p>QUANTITY</p>
          <p>TOTAL</p>
        </div>
        {currentQuote?.products?.map((order, index) => (
          <OrderReviewItem
            key={order.id}
            item={order}
            orderConfig={getConfig(order.id)}
            nameForUser={getLatestLeadTimeForUser(quoteDetail?.line_items)}
            deliveryDays={getLatestDeliveryDate(quoteDetail?.line_items)}
          ></OrderReviewItem>
        ))}
      </div>

      {/* <div className="order-note">
        <Form layout="vertical" rootClassName="order-note-form">
          <div className="order-note-label">
            <img src={NotesIcon} alt="" width={20} height={20} />
            <p>NOTES</p>
          </div>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <TextField
                placeholder="Click to add project notes"
                className="custom-notes"
                {...field}
              ></TextField>
            )}
          />
        </Form>
      </div> */}

      <div className="collapse-footer">
        <Button customType={Type.NEUTRAL} onClick={handleSubmit(onSubmit)}>
          Next Step
        </Button>
      </div>
    </>
  )
}

export default OrderReview
