import { ECollapseKey, EPaymentMethod } from "@/enums/checkout.enum"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { ICheckoutForm } from "@/interfaces/checkout.interface"
import { IDeliveryForm } from "@/interfaces/delivery.interface"
import {
  useGetPaymentLinkMutation,
  useSubmitMutation,
  useSubmitOrderMutation,
  useUpdateInvoiceMutation
} from "@/services/apiDigifabster/order"
import {
  OrderDetailResponse,
  QuoteResponse,
  useGetTaxRateMutation
} from "@/services/apiDigifabster/quote"
import { useGetShippingRateMutation } from "@/services/apiDigifabster/shipping"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { IUserStore } from "@/store/user"
import { getBiggestXObject } from "@/utils/arrayHelper"
import { getInvoiceHash } from "@/utils/stringHelper"
import { UploadFile } from "antd"
import { RcFile } from "antd/es/upload"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useCurrency } from "./useCurrency"

export interface IUploadData {
  orderId?: number
  files: Record<string, UploadFile>
  error?: string
}

export const useCheckoutFlow = () => {
  const [submit, { isLoading: isLoadingSubmitOrder }] = useSubmitMutation()
  const [submitQuote, { isLoading: isLoadingSubmitQuote }] =
    useSubmitOrderMutation()
  const [
    updateInvoice,
    { isLoading: isLoadingUpdateInvoice, isSuccess: isSuccessUpdate }
  ] = useUpdateInvoiceMutation()
  const [getShippingRate, { isLoading: getShippingRateLoading }] =
    useGetShippingRateMutation()
  const [getTaxRate, { isLoading: getTaxRateLoading }] = useGetTaxRateMutation()
  const [getPaymentLink, { isLoading: getPaymentLinkLoading }] =
    useGetPaymentLinkMutation()
  const navigate = useNavigate()
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { getCurrency } = useCurrency()
  const country = quoteDetail?.country
  const currency = getCurrency(country)

  const redirectInit = (
    currentQuote?: OrderDetailResponse,
    invoice_hash?: string,
    invoiceId?: string
  ) => {
    if (!currentQuote || !currentQuote.line_items || !currentQuote.status)
      return
    if (
      currentQuote?.status !== EOrderStatus.INITIAL &&
      currentQuote?.status !== EOrderStatus.FIRM_OFFER_SENT
    ) {
      const invoice_id = invoiceId || currentQuote?.main_invoice?.id
      const invoiceHash =
        invoice_hash || getInvoiceHash(currentQuote?.main_invoice?.pay_url)
      if (invoice_id && invoiceHash)
        return navigate(
          `/new-quote/checkout/${currentQuote?.id}/submitted/invoices/${invoiceHash}/${invoiceHash}`
        )
      return navigate(`/new-quote`)
    }
  }

  const backFunction = (
    currentQuote?: QuoteResponse,
    invoice_hash?: string,
    invoiceId?: string
  ) => {
    if (
      currentQuote?.status === EOrderStatus.FIRM_OFFER_SENT &&
      invoiceId &&
      invoice_hash
    ) {
      if (invoiceId && invoice_hash) {
        navigate(`/quotes/invoices/${invoiceId}/${invoice_hash}`)
      } else {
        navigate(`/quotes/${currentQuote?.id}`)
      }
    } else {
      navigate(`/new-quote/specification/${currentQuote?.id}`)
    }
  }

  const sumbitOrder = async (
    formData: ICheckoutForm,
    currentStatus: EOrderStatus,
    id: number,
    paymentMethod: EPaymentMethod
  ) => {
    const {
      delivery_address,
      billing,
      shipping_method,
      shipping_carrier,
      shipping_note,
      notes
    } = formData
    const splitEmails = delivery_address?.email
      .split(/\s*,\s*/)
      .map((email) => email.trim())
    const email = splitEmails[0] || ""
    const additional_email = splitEmails.slice(1).join(", ")

    const resSubmitOrder = await submit({
      orderId: id,
      arg: {
        userId: userInfoNew?.id,
        country: country,
        name: delivery_address?.name,
        surname: delivery_address?.surname,
        phone: delivery_address?.phoneNumber,
        email,
        notes: notes,
        shippingMethod: {
          method: shipping_method,
          carrier: shipping_carrier,
          note: shipping_note
        },
        shippingAddress: {
          addressLine1: delivery_address?.addressLine1,
          addressLine2: delivery_address?.addressLine2,
          country: delivery_address?.country.iso2 || "",
          state: delivery_address?.state.iso2 || "",
          city: delivery_address?.city,
          postalCode: delivery_address?.zipCode,
          companyName: delivery_address?.company,
          additionalEmail: additional_email
        },
        currentStatus,
        billingAddress:
          paymentMethod === EPaymentMethod.CREDIT_CARD
            ? undefined
            : {
                addressLine1: billing?.addressLine1,
                addressLine2: billing?.addressLine2,
                country: billing?.country.iso2 || "",
                state: billing?.state.iso2 || "",
                city: billing?.city,
                postalCode: billing?.zipCode,
                companyName: billing?.company,
                name: billing?.firstName,
                surname: billing?.lastName,
                phone: billing?.phoneNumber,
                email: billing?.accountPayEmail,
                poNumber: billing?.poNumber,
                taxNumber: billing?.taxNumber
              },
        dimensions: getBiggestXObject(quoteDetail)
      }
    })

    return resSubmitOrder
  }

  const sumbitQuote = async (
    formData: ICheckoutForm,
    currentStatus: EOrderStatus,
    id: number,
    paymentMethod: EPaymentMethod
  ) => {
    const {
      delivery_address,
      billing,
      shipping_method,
      shipping_carrier,
      shipping_note,
      notes
    } = formData
    const splitEmails = delivery_address?.email
      .split(/\s*,\s*/)
      .map((email) => email.trim())
    const email = splitEmails[0] || ""
    const additional_email = splitEmails.slice(1).join(", ")

    const resSubmitOrder = await submitQuote({
      orderId: id,
      arg: {
        userId: userInfoNew?.id,
        country: country,
        name: delivery_address?.name,
        surname: delivery_address?.surname,
        phone: delivery_address?.phoneNumber,
        email,
        notes: notes,
        shippingMethod: {
          method: shipping_method,
          carrier: shipping_carrier,
          note: shipping_note
        },
        shippingAddress: {
          addressLine1: delivery_address?.addressLine1,
          addressLine2: delivery_address?.addressLine2,
          country: delivery_address?.country.iso2 || "",
          state: delivery_address?.state.iso2 || "",
          city: delivery_address?.city,
          postalCode: delivery_address?.zipCode,
          companyName: delivery_address?.company,
          additionalEmail: additional_email
        },
        currentStatus,
        billingAddress:
          paymentMethod === EPaymentMethod.CREDIT_CARD
            ? undefined
            : {
                addressLine1: billing?.addressLine1,
                addressLine2: billing?.addressLine2,
                country: billing?.country.iso2 || "",
                state: billing?.state.iso2 || "",
                city: billing?.city,
                postalCode: billing?.zipCode,
                companyName: billing?.company,
                name: billing?.firstName,
                surname: billing?.lastName,
                phone: billing?.phoneNumber,
                email: billing?.accountPayEmail,
                poNumber: billing?.poNumber,
                taxNumber: billing?.taxNumber
              },
        dimensions: getBiggestXObject(quoteDetail)
      }
    })

    return resSubmitOrder
  }

  const updatePO = async (
    invoiceId: number,
    invoiceHash: string,
    attached_po: UploadFile<any>,
    po_number?: string
  ) => {
    const file = attached_po.originFileObj
    const uploadPayload = new FormData()
    if (file) uploadPayload.append("attached_po", file as RcFile, file?.name)
    if (po_number) uploadPayload.append("po_number", po_number)

    const payloadUpdateInvoice = {
      invoice_id: invoiceId,
      invoice_hash: invoiceHash,
      arg: uploadPayload
    }
    await updateInvoice(payloadUpdateInvoice)
  }

  const getShippingRateAndTax = async (
    orderId: number,
    data: IDeliveryForm,
    country?: string
  ) => {
    return await Promise.allSettled([
      getShippingRate({
        orderId,
        arg: {
          country: data.country.iso2 || "",
          state: data.state.iso2 || "",
          postalCode: data.zipCode,
          city: data.city,
          userCountry: country || quoteDetail?.country,
          dimensions: getBiggestXObject(quoteDetail)
        }
      }),
      getTaxRate({
        orderId,
        arg: {
          country: data.country.iso2 || "",
          state: data.state.iso2 || "",
          postalCode: data.zipCode
        }
      })
    ])
  }

  const beforeUnloadHandler =
    (formData: any, isSubmitting: boolean, isLogout: boolean) =>
    (event: BeforeUnloadEvent) => {
      if (isLogout) return
      if (Object.keys(formData).length > 0 && !isSubmitting) {
        const confirmationMessage =
          "Are you sure you want to leave? All progress of the current order will be lost."
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }

  const disabledSubmit = (
    isRequiresReview: boolean,
    doneKeysCheckout: number[]
  ) => {
    const requiredKeys = [
      ECollapseKey.ORDER_REVIEW,
      ECollapseKey.DELIVERY_ADDRESS,
      ECollapseKey.SHIPPING_METHOD
    ]
    const isEnableKeys = isRequiresReview
      ? requiredKeys.filter((item) => item !== ECollapseKey.PAYMENT)
      : requiredKeys
    return (
      !isEnableKeys.every((key) => doneKeysCheckout.includes(key)) ||
      getTaxRateLoading ||
      getShippingRateLoading
    )
  }

  const goToPaymentPage = async (
    orderId: number,
    clientSecret: string,
    invoice_hash?: string,
    invoiceId?: string
  ) => {
    if (invoiceId && invoice_hash)
      return navigate(
        `/stripe-page/${clientSecret}/${orderId}/${invoiceId}/${invoice_hash}`
      )
    return navigate(`/stripe-page/${clientSecret}/${orderId}`)
  }

  return {
    sumbitOrder,
    sumbitQuote,
    updatePO,
    getShippingRateAndTax,
    beforeUnloadHandler,
    redirectInit,
    backFunction,
    disabledSubmit,
    goToPaymentPage,
    currency,
    isLoadingSubmitOrder,
    isSuccessUpdate,
    isLoadingUpdateInvoice,
    getTaxRateLoading,
    getShippingRateLoading,
    getPaymentLinkLoading,
    isLoadingSubmitQuote
  }
}
