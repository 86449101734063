import MinusCheckbox from "@/components/common/atom/MinusCheckbox/MinusCheckbox"
import "./Toolbar.scss"
import { useState } from "react"
import { CustomCheckbox } from "@/components"
import { Size } from "@/enums/common.enum"

interface ToolbarProps {
  isSelectedAll: boolean
  deselectAll: () => void
  isSelectedNone: boolean
  selectAll: () => void
}

function Toolbar({
  isSelectedAll,
  isSelectedNone,
  deselectAll,
  selectAll
}: ToolbarProps) {
  return (
    <div className="sticky-container">
      <div className="toolbar-container">
        {/* <div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `"Open Sans", sans-serif`
            }
          }}
        >
          <CustomCheckbox
            indeterminate={indeterminate}
            checked={isSelectAllChecked}
            onChange={(e) => onSelectAll(e.target.checked)}
          >
            <p className="text-checkbox">Select All</p>
          </CustomCheckbox>
        </ConfigProvider>
      </div> */}
        <div className="toolbar-checkbox">
          {isSelectedAll ? (
            <CustomCheckbox
              size={Size.MEDIUM}
              checked={isSelectedAll}
              onChange={deselectAll}
            />
          ) : (
            <MinusCheckbox
              isChecked={!isSelectedNone}
              onDeselect={deselectAll}
              onSelect={selectAll}
            />
          )}
          <div>PART</div>
        </div>

        <div>Specifications</div>
        <div>POST PROCESSING</div>
        <div>Quantity</div>
        <div>Price</div>
        {/* <div className="toolbar-button">
        <Button
          hierarchy={ButtonHierarchy.OUTLINE}
          onClick={() => onEditPart(true)}
        >
          <EditOutlined /> Edit Part
        </Button>
      </div> */}
      </div>
    </div>
  )
}

export default Toolbar
