import { priceDisplay } from "@/utils/functionHelper"
import "./QuoteItem.scss"
import { LineItem } from "@/services/apiDigifabster/quote"
import { CustomBadge } from "@/components"
import { BadgeColor, BadgeType, Size } from "@/enums/common.enum"
import { DrawingIcon } from "@/assets"
import {
  formatRangeDeliveryDate,
  getDiffDeliveryDays,
  getLatestDeliveryDate,
  getLatestLeadTimeForUser
} from "@/utils/timeFormatHelper"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { replacePostProductionTitle } from "@/utils/stringHelper"
import { DATE_RANGE_FORMATS } from "@/constants/common.constant"
import { useMemo } from "react"
import { MANUAL_REQUEST_MATERIAL_IDS } from "@/constants/order.constrant"
import { EOrderStatus } from "@/enums/quotesList.enum"

interface IQuoteItemProps {
  item: LineItem
  currency: string
  deliveryDays?: number
}

function QuoteItem({ item, currency, deliveryDays }: IQuoteItemProps) {
  const { product, config, drawing_files } = item
  const { invoiceDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""
  const isHidePrice = useMemo(() => {
    return (
      item?.status === EOrderStatus.WAITING_FOR_REVIEW ||
      MANUAL_REQUEST_MATERIAL_IDS.includes(item.material)
    )
  }, [item])
  return (
    <div className="quote-item-box">
      <div className="quote-item-box-col-1">
        <div>
          <img src={product.thumb_120x120} alt="" width={68} height={54} />
        </div>
        <div className="">
          <p className="quote-item-box-col-1-title">{product.title}</p>
          <div className="quote-item-box-col-1-size">
            <p>{`${
              product?.units === "mm"
                ? Math.round(product?.size?.x || 0)
                : product?.size?.x?.toFixed(2) || 0
            } x 
            ${
              product?.units === "mm"
                ? Math.round(product?.size?.y || 0)
                : product?.size?.y?.toFixed(2) || 0
            } x 
            ${
              product?.units === "mm"
                ? Math.round(product?.size?.z || 0)
                : product?.size?.z?.toFixed(2) || 0
            } 
            ${product?.units || ""}`}</p>
          </div>

          {!!drawing_files.length && (
            <div className="quote-drawings">
              {drawing_files?.map((item: any) => (
                <div key={item.id} className="quote-drawings-item">
                  <img width={12} height={12} src={DrawingIcon} alt="" />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="quote-item-box-col-2">
        <p>{item.technology_title}</p>
        <p>
          {item.material_title} ,{item?.config?.color?.name_for_user}
        </p>
        <p>{config?.layer_thickness?.name_for_user || ""} </p>
        <p>
          {config?.filling?.name_for_user ||
            config?.filling?.value?.toFixed(1) + " percent" ||
            ""}
        </p>
      </div>
      <div className="quote-item-box-col-5">
        <p>Visual Inspection</p>
        {config?.post_production?.length > 0
          ? config.post_production.map((item) => (
              <p key={item.title}>
                {replacePostProductionTitle(item.title, country)}{" "}
                {item.countable && `(${item.quantity})`}
              </p>
            ))
          : ""}{" "}
      </div>
      <div className="quote-item-box-col-3">
        <p>{item.amount}</p>
      </div>
      <div className="quote-item-box-col-4">
        <div className="">
          <p>
            {isHidePrice ? (
              <>{currency}--.--</>
            ) : (
              <>
                {currency}
                {priceDisplay(item?.total_price || 0)}
              </>
            )}{" "}
          </p>
          <p>
            {isHidePrice ? (
              <>{currency}--.--</>
            ) : (
              <>
                {currency}
                {priceDisplay(item?.unit_price || 0)} / part
              </>
            )}
          </p>
        </div>

        <CustomBadge
          customClassName="quote-item-box-col-4-badge"
          content={formatRangeDeliveryDate(
            getDiffDeliveryDays(
              getLatestLeadTimeForUser(
                invoiceDetail?.order?.line_items as unknown as LineItem[]
              )
            ),
            getLatestDeliveryDate(
              invoiceDetail?.order?.line_items as unknown as LineItem[]
            ),
            new Date(invoiceDetail?.order?.date),
            {
              from: DATE_RANGE_FORMATS.shortWithComma,
              to: DATE_RANGE_FORMATS.shortWithComma
            }
          )}
          color={BadgeColor.SUCCESS}
          type={BadgeType.TONAL}
          size={Size.SMALL}
        />
      </div>
    </div>
  )
}

export default QuoteItem
