import { useEffect } from "react"
import { Form, Row, Col, ConfigProvider } from "antd"
import { FormField, TextField, Button, InputView } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { FieldError, useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { changePasswordSchema } from "@/validations/profile.validation"
import { showError, showSuccess } from "@/utils/Toast"
import "./PasswordForm.scss"
import { useAppLoading } from "@/hooks/useLoading"
import { useUpdatePasswordMutation } from "@/services/apiDigifabster/user"

interface IPasswordFormProps {
  isPasswordChange: boolean
  setChangePassword: (isChange: boolean) => void
}

interface IChangePasswordInputs {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

interface IButtonSaveAndCancel {
  isPasswordChange: boolean
  onCancel: () => void
  onSave: () => void
}

const ButtonSaveAndCancel = ({
  isPasswordChange,
  onCancel,
  onSave
}: IButtonSaveAndCancel) => {
  return (
    <>
      {isPasswordChange && (
        <Row justify="end">
          <Col style={{ paddingTop: "32px", marginRight: 8 }}>
            <Button
              onClick={onCancel}
              customType={Type.NEUTRAL}
              hierarchy={ButtonHierarchy.LINK}
              customSize={Size.MEDIUM}
              customClassName="btn-save"
            >
              Cancel
            </Button>
          </Col>
          <Col style={{ paddingTop: "32px" }}>
            <Button
              onClick={onSave}
              customType={Type.PRIMARY}
              customSize={Size.MEDIUM}
              customClassName="btn-save"
            >
              Save
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

function PasswordForm({
  setChangePassword,
  isPasswordChange
}: IPasswordFormProps) {
  const errorStatus = (errors?: FieldError) => (errors ? "error" : "")
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset
  } = useForm<IChangePasswordInputs>({
    defaultValues: {},
    resolver: yupResolver(changePasswordSchema)
  })

  const [updatePassword, { isLoading, isSuccess, isError, error }] =
    useUpdatePasswordMutation()
  useAppLoading([isLoading])

  useEffect(() => {
    if (isError) {
      const errorData = error as any
      if (errorData && errorData?.status === 404) {
        setError("currentPassword", {
          message: "Current password is incorrect."
        })
      } else {
        showError("Password change failed. Please try again")
      }
    }

    if (isSuccess) {
      showSuccess("Password changed successfully")
      reset()
      setChangePassword(false)
    }
  }, [isError, isSuccess])

  const handleChangePassword = () => {
    setChangePassword(true)
  }

  const handleCancel = () => {
    setChangePassword(false)
    reset()
  }

  const handleSave = (data: IChangePasswordInputs) => {
    updatePassword(data)
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `"Open Sans", sans-serif`
        }
      }}
    >
      <Form onFinish={handleSubmit(handleSave)} className="password-font-form">
        <Row>
          <Col span={12}>
            <FormField
              textLabel="Current password"
              errorText={errors.currentPassword?.message}
            >
              {isPasswordChange ? (
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="password"
                      status={errorStatus(errors.currentPassword)}
                      {...field}
                      placeholder="Enter your current password"
                    />
                  )}
                />
              ) : (
                <InputView>******</InputView>
              )}
            </FormField>
          </Col>
        </Row>
        {isPasswordChange && (
          <Row gutter={14} style={{ paddingTop: "14px" }}>
            <Col span={12}>
              <FormField
                textLabel="New password"
                errorText={errors.newPassword?.message}
              >
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="password"
                      status={errorStatus(errors.newPassword)}
                      {...field}
                      placeholder="Enter your new password"
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Confirm new password"
                errorText={errors.confirmPassword?.message}
              >
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.confirmPassword)}
                      {...field}
                      type="password"
                      placeholder="Confirm your new password"
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
        )}
        {isPasswordChange ? (
          <ButtonSaveAndCancel
            onSave={handleSubmit(handleSave)}
            isPasswordChange={isPasswordChange}
            onCancel={handleCancel}
          />
        ) : (
          <Row justify="end" align="middle">
            <Col style={{ paddingTop: "32px" }}>
              <Button
                onClick={handleChangePassword}
                customType={Type.PRIMARY}
                hierarchy={ButtonHierarchy.OUTLINE}
                customSize={Size.MEDIUM}
                customClassName="btn-change"
              >
                Change
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </ConfigProvider>
  )
}

export default PasswordForm
