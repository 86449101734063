import { TextField } from "@/components"
import { INotesForm } from "@/interfaces/notes.interface"
import { Form } from "antd"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import "./Notes.scss"
import { debounce } from "lodash"

interface IOrderReviewProps {
  onSubmit: (data: INotesForm) => void
}

const Notes = ({ onSubmit }: IOrderReviewProps) => {
  const { control, handleSubmit } = useForm<INotesForm>({
    defaultValues: {
      notes: ""
    }
  })

  const debouncedSubmit = debounce(() => handleSubmit(onSubmit)(), 500)
  return (
    <div className="note-container">
      <Form layout="vertical" rootClassName="note-container-form">
        <div className="note-container-text">
          <p>Notes</p>
        </div>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <TextField
              placeholder="Add notes"
              className="note-textfield"
              {...field}
              onChange={(e) => {
                field.onChange(e)
                debouncedSubmit()
              }}
            ></TextField>
          )}
        />
      </Form>
    </div>
  )
}

export default Notes
