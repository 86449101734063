import { Form } from "antd"
import "./QuoteSummary.scss"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import { DownloadIcon } from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import { priceDisplay } from "@/utils/functionHelper"
import { useMemo } from "react"
import { useGetPaymentLinkMutation } from "@/services/apiDigifabster/order"
import * as toast from "@/utils/Toast"
import { DIGIFABSTER_INVOICE_URL } from "@/constants/common.constant"
import { useNavigate, useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { useCurrency } from "@/hooks/useCurrency"
import { safeConcatUrl } from "@/utils/stringHelper"

enum EStatusPayment {
  QUOTE = "quote",
  PO_PROVIDE = "po_provide",
  READY = "ready",
  PAID = "paid",
  QUOTE_SENT = "firm_offer_sent",
  PO_PAYMNET_DUE = "po_payment_due"
}
const statusPayment = {
  [EStatusPayment.QUOTE]: {
    title: "Preliminary Quote",
    classname: "invoice-summary-box-header-status-quote"
  },
  [EStatusPayment.PO_PROVIDE]: {
    title: "PO provided",
    classname: ""
  },
  [EStatusPayment.READY]: {
    title: "Ready for payment",
    classname: ""
  },
  [EStatusPayment.QUOTE_SENT]: {
    title: "Quote Sent",
    classname: ""
  },
  [EStatusPayment.PO_PAYMNET_DUE]: {
    title: "Payment due",
    classname: ""
  },
  [EStatusPayment.PAID]: {
    title: "Payment succeeded",
    classname: "invoice-summary-box-header-status-paid"
  }
}

const getFileName = (urlString: string) => {
  if (urlString && urlString.startsWith("http")) {
    try {
      const url = new URL(urlString)
      return url.pathname.split("/").pop()
    } catch (error) {
      return ""
    }
  } else {
    return
  }
}

function QuoteSummary({
  waitingPrice,
  currency
}: {
  waitingPrice: number
  currency: string
}) {
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { settings } = useSelector((state: RootState) => state.user)
  const [getPaymentLink, { isLoading: isLoadingGetPaymentLink }] =
    useGetPaymentLinkMutation()
  const order = quoteDetail
  const isPaid = quoteDetail?.main_invoice?.is_paid
  const navigate = useNavigate()

  useAppLoading([isLoadingGetPaymentLink])

  const statusPaid = useMemo(() => {
    if (!isPaid) {
      switch (order?.status) {
        case EOrderStatus.WAITING_FOR_REVIEW:
          return EStatusPayment.QUOTE
        case EOrderStatus.FIRM_OFFER_SENT:
          return EStatusPayment.QUOTE_SENT
        case EOrderStatus.PO_PROVIDED:
          return EStatusPayment.PO_PROVIDE
        case EOrderStatus.PO_PAYMNET_DUE:
          return EStatusPayment.PO_PAYMNET_DUE
        default:
          return EStatusPayment.READY
      }
    }
    return EStatusPayment.PAID
  }, [quoteDetail, order?.status])

  const handlePlaceOrder = async () => {
    const { data, error } = await getPaymentLink({
      orderId: quoteDetail?.id || 0
    })
    const errorMsg = (error as any)?.data?.message || ""
    if (error || !data) return toast.showError(errorMsg)
    window.location.href = data.payUrl
  }

  return (
    <Form>
      <div className="quotes-detail-summary">
        <div className="quotes-detail-summary-box">
          <div className="quotes-detail-summary-box-header">
            <div className="quotes-detail-summary-box-header-layout">
              <p className="quotes-detail-summary-box-header-layout-title">
                Quote Summary
              </p>
            </div>
          </div>
          <div className="quotes-detail-summary-box-content">
            <div className="quotes-detail-summary-box-content-price">
              <p>Subtotal</p>
              <p>
                {currency}
                {priceDisplay(
                  Number(
                    (quoteDetail?.models_naked_price || 0) - (waitingPrice || 0)
                  )
                )}
              </p>
            </div>
            {Number(quoteDetail?.startup_cost) > 0 && (
              <div className="quotes-detail-summary-box-content-price">
                <p>Minimum Order Fee</p>

                <p>
                  {currency}
                  {priceDisplay(quoteDetail?.startup_cost || 0)}
                </p>
              </div>
            )}
            {Number(quoteDetail?.price_corrections_cost) > 0 && (
              <div className="quotes-detail-summary-box-content-price">
                <p>Additional Services</p>

                <p>
                  {currency}
                  {priceDisplay(
                    Number(quoteDetail?.price_corrections_cost) || 0
                  )}
                </p>
              </div>
            )}
            <div className="quotes-detail-summary-box-content-price">
              <p>Delivery</p>
              <p>
                {currency}
                {priceDisplay(Number(quoteDetail?.delivery_price))}
              </p>
            </div>
            <div className="quotes-detail-summary-box-content-price">
              <p>Tax</p>
              <p>Calculated at checkout</p>
            </div>
          </div>
          <div className="quotes-detail-summary-box-total">
            <div className="quotes-detail-summary-box-total-price">
              <p>Total</p>
              <p>
                {currency}
                {priceDisplay(
                  Number((quoteDetail?.total_price || 0) - (waitingPrice || 0))
                )}
              </p>
            </div>
            <div className="quotes-detail-summary-box-total-btn">
              {!isPaid &&
                ![
                  EOrderStatus.WAITING_FOR_REVIEW,
                  EOrderStatus.PO_PROVIDED,
                  EOrderStatus.PO_PAYMNET_DUE,
                  EOrderStatus.CANCEL_BY_ADMIN,
                  EOrderStatus.CANCEL_BY_USER
                ].includes((order?.status as EOrderStatus) || "") && (
                  <>
                    <Button
                      customClassName="btn-place-order"
                      customSize={Size.LARGE}
                      onClick={() => navigate(`checkout/${quoteDetail?.id}`)}
                      disabled={quoteDetail?.line_items?.length === 0}
                    >
                      Proceed To Checkout
                    </Button>
                  </>
                )}
            </div>
            <div className="quotes-detail-summary-box-total-ask">
              <p>
                Have Questions?&nbsp;
                <a href={`mailto:${settings.support_email}`}>Ask for help</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default QuoteSummary
