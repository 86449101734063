import { ImageProduct, ImageProduct2 } from "@/assets"
import { EMeasurement } from "@/enums/editSpecifications.enum"
import { ISelectDropdownItem } from "@/interfaces/common.interface"

export const measurementOptions: ISelectDropdownItem[] = [
  { id: EMeasurement.MM, title: "mm" },
  { id: EMeasurement.CM, title: "cm" },
  { id: EMeasurement.DM, title: "dm" }
]

export const MANUAL_REQUEST_CONFIG = {
  layer_thickness: "df31bbaa-ffbd-4b73-b693-35be9efae1e7",
  filling: "b6dac54d-85fb-4600-b22c-09f68a917f8c",
  lead_time: "977bd2a0-4ec1-483b-ac86-42f0b83078a4"
}

export const MANUAL_REQUEST_MATERIAL_ID = 66403
const MANUAL_REQUEST_MATERIAL_IDS_SMALL_STAGE = [
  66987, 66988, 66989, 66990, 66985, 66986, 66967, 66968, 66969, 66970, 66971,
  66974, 66975, 66976, 66977, 66978, 66979, 66980, 66981, 66982, 66983, 66984,
  66991, 66992
]

const MANUAL_REQUEST_MATERIAL_IDS_SMALL_PROD = [
  67137, 67138, 67139, 67140, 67131, 67132, 67146, 67147, 67148, 67149, 67150,
  67122, 67123, 67124, 67125, 67126, 67126, 67128, 67156, 67157, 67158, 67159,
  67160, 67161
]

const MANUAL_REQUEST_MATERIAL_IDS_BIG_PROD = [
  67133, 67134, 67135, 67136, 67129, 67130, 67141, 67145, 67144, 67169, 67143,
  67115, 67118, 67116, 67117, 67119, 67120, 67121, 67151, 67152, 67153, 67154,
  67171, 67154
]

const MANUAL_REQUEST_MATERIAL_IDS_BIG_STAGE = [
  66943, 66944, 66945, 66946, 66947, 66948, 66942, 66956, 66955, 66965, 66966,
  67063, 67064, 67068, 67069, 67070, 67071, 67072, 67075, 67076, 67077, 67078,
  66962, 67082
]

export const MANUAL_REQUEST_MATERIAL_IDS_BIG = [
  ...MANUAL_REQUEST_MATERIAL_IDS_BIG_STAGE,
  ...MANUAL_REQUEST_MATERIAL_IDS_BIG_PROD
]
export const MANUAL_REQUEST_MATERIAL_IDS_SMALL = [
  ...MANUAL_REQUEST_MATERIAL_IDS_SMALL_STAGE,
  ...MANUAL_REQUEST_MATERIAL_IDS_SMALL_PROD
]
export const MANUAL_REQUEST_MATERIAL_IDS = [
  ...MANUAL_REQUEST_MATERIAL_IDS_SMALL,
  ...MANUAL_REQUEST_MATERIAL_IDS_BIG,
  MANUAL_REQUEST_MATERIAL_ID
]
