import { Config, Product } from "@/services/apiDigifabster/quote"
import { useMemo } from "react"
import "./OrderReviewItem.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { ModelResponse } from "@/services/apiDigifabster/model"
import { CustomBadge } from "@/components"
import { BadgeColor, BadgeType, Size } from "@/enums/common.enum"
import { priceDisplay } from "@/utils/functionHelper"
import { useCurrency } from "@/hooks/useCurrency"
import {
  formatRangeDeliveryDate,
  getDiffDeliveryDays
} from "@/utils/timeFormatHelper"
import { DrawingIcon } from "@/assets"
import { IUserStore } from "@/store/user"
import {
  checkPlaceholderFile,
  getFileNameWithoutExtension,
  replacePostProductionTitle
} from "@/utils/stringHelper"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { IQuoteStore } from "@/store/quote"
import { DATE_RANGE_FORMATS } from "@/constants/common.constant"
import { EModelAnalysisStatus, IModelAnalysis } from "@/store/product"
import { MANUAL_REQUEST_MATERIAL_IDS } from "@/constants/order.constrant"
import { useCheckoutFlow } from "@/hooks"

interface IOrderReviewItemProps {
  item: Product
  deliveryDays?: number
  nameForUser?: string
  orderConfig?: Config
}

function OrderReviewItem({
  item,
  deliveryDays,
  nameForUser,
  orderConfig
}: IOrderReviewItemProps) {
  const {
    config_formatted_json,
    model_title,
    model_id,
    material_display_name,
    config,
    id,
    material_id,
    total_price,
    price_per_part,
    drawing_files,
    accept_drawing,
    count
  } = item

  const { currency } = useCheckoutFlow()
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""
  const currentModel = useSelector<RootState, ModelResponse | undefined>(
    (s) => s.quote.currentModels[model_id]
  )
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const tech = useMemo(
    () => config_formatted_json?.find((item) => item.key === "Technology"),
    [config_formatted_json]
  )
  const status = useMemo(
    () => quoteDetail?.line_items?.find((e) => e.id === id)?.status,
    [quoteDetail]
  )
  const self_notes = useMemo(
    () => quoteDetail?.line_items?.find((e) => e.id === id)?.self_notes,
    [quoteDetail]
  )
  const modelAnalysis = useSelector<RootState, IModelAnalysis | undefined>(
    (s) => s.product.modelsAnalysis[model_id || 0]
  )
  const isHidePrice = useMemo(() => {
    return (
      status === EOrderStatus.WAITING_FOR_REVIEW ||
      MANUAL_REQUEST_MATERIAL_IDS.includes(material_id) ||
      modelAnalysis?.status === EModelAnalysisStatus.ERROR
    )
  }, [material_id, status, modelAnalysis])

  return (
    <div className="order-checkout-container">
      <div className="order-review-box-test">
        <div className="order-item-box-col-1">
          <div>
            <img
              src={currentModel?.thumb_120x120}
              alt=""
              width={77}
              height={69}
            />
          </div>
          <div className="">
            <p className="order-item-box-col-1-title">
              {checkPlaceholderFile(self_notes || "") &&
              status === EOrderStatus.WAITING_FOR_REVIEW
                ? getFileNameWithoutExtension(currentModel?.title || "")
                : currentModel?.title}
            </p>
            <div className="order-item-box-col-1-size">
              <p>{`${
                currentModel?.units === "mm"
                  ? Math.round(currentModel?.size?.x || 0)
                  : currentModel?.size?.x?.toFixed(2) || 0
              } x 
              ${
                currentModel?.units === "mm"
                  ? Math.round(currentModel?.size?.y || 0)
                  : currentModel?.size?.y?.toFixed(2) || 0
              } x 
              ${
                currentModel?.units === "mm"
                  ? Math.round(currentModel?.size?.z || 0)
                  : currentModel?.size?.z?.toFixed(2) || 0
              } 
              ${currentModel?.units || ""}`}</p>
            </div>
            {/* <div className="order-item-box-col-1-volume">
              <p>
                {currentModel?.volume.toFixed(2)}{" "}
                {currentModel?.units ? currentModel?.units + "3" : ""}
              </p>
            </div> */}

            {accept_drawing && (
              <div className="order-drawings">
                {drawing_files?.map((item: any) => (
                  <div key={item.id} className="order-drawings-item">
                    <img width={12} height={12} src={DrawingIcon} alt="" />
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="order-item-box-col-2">
          <p>{tech?.value || orderConfig?.technology?.name || ""}</p>
          <p>
            {material_display_name} ,{config?.color?.name_for_user || ""}
          </p>
          <p>{config?.layer_thickness?.name_for_user || ""} </p>
          <p>
            {config?.filling?.name_for_user ||
              config?.filling?.value?.toFixed(1) + " percent" ||
              ""}
          </p>
        </div>
        <div className="order-item-box-col-2">
          <p>Visual Inspection</p>
          {config?.post_production?.length > 0
            ? config.post_production.map((item) => (
                <p key={item.title}>
                  {replacePostProductionTitle(item.title, country)}{" "}
                  {item.countable && `(${item.quantity})`}
                </p>
              ))
            : ""}{" "}
        </div>
        <div className="order-item-box-col-3">
          <p>{count}</p>
        </div>
        <div className="order-item-box-col-4">
          <div className="">
            <p>
              {isHidePrice ? (
                <>{currency}--.--</>
              ) : (
                <>
                  {currency}
                  {priceDisplay(total_price || 0)}
                </>
              )}
            </p>
            <p>
              {isHidePrice ? (
                <>{currency}--.--</>
              ) : (
                <>
                  {currency}
                  {priceDisplay(price_per_part || 0)} / part
                </>
              )}
            </p>
          </div>

          <CustomBadge
            customClassName="order-item-box-col-4-badge"
            content={formatRangeDeliveryDate(
              getDiffDeliveryDays(nameForUser || ""),
              deliveryDays,
              new Date(),
              {
                from: DATE_RANGE_FORMATS.shortWithComma,
                to: DATE_RANGE_FORMATS.shortWithComma
              }
            )}
            color={BadgeColor.SUCCESS}
            type={BadgeType.TONAL}
            size={Size.SMALL}
          />
        </div>
      </div>
      {/* {accept_drawing && (
        <div className="order-drawings">
          {drawing_files?.map((item: any) => (
            <div key={item.id} className="order-drawings-item">
              <p>{item.name}</p>
              <CloseOutlined
                width={12}
                height={12}
                onClick={() => handleDeleteDrawing(item.id)}
              />
            </div>
          ))}
        </div>
      )}
      <div className="order-production-time">
        <p>Ready soon as:</p>
        <p>{calculateDeliveryDate(config.lead_time.days)}</p>
      </div> */}
    </div>
  )
}

export default OrderReviewItem
