import { useCheckWallThicknessMutation } from "@/services/apiDigifabster/model"
import { Product } from "@/services/apiDigifabster/quote"
import { chunkArray } from "@/utils/functionHelper"
import { useState } from "react"

export const useCheckWallThickness = () => {
  const [checkWallThickness, { isError: isWallThickNessError }] =
    useCheckWallThicknessMutation()
  const [isProcessing, setIsProcessing] = useState(false)

  const callCheckWallThickness = (materialId: number, modelIds: number[]) => {
    const chunkedModels = chunkArray(modelIds, 10)
    return Promise.all(
      chunkedModels.map((e) =>
        checkWallThickness({
          materials_ids: [materialId],
          models_ids: e
        })
      )
    )
  }
  const flattenData = (dataArray: any[]) => {
    const flattenedData: Record<string, any>[] = []

    dataArray.forEach((item) => {
      const data = item.data
      if (data && typeof data === "object") {
        Object.keys(data).forEach((materialId) => {
          const models = data[materialId]
          if (models && typeof models === "object") {
            Object.keys(models).forEach((modelId) => {
              flattenedData.push({ [modelId]: models[modelId] })
            })
          }
        })
      }
    })

    return flattenedData
  }

  const handleCheckWallThickness = async (product: Product[]) => {
    setIsProcessing(true)
    const sameMaterialId = product.reduce<Record<number, number[]>>(
      (pre, cur) => ({
        ...pre,
        [cur.material_id]: pre[cur.material_id]
          ? [...pre[cur.material_id], cur?.parent_model_id || cur?.model_id]
          : [cur?.parent_model_id || cur?.model_id]
      }),
      {}
    )

    const rs = await Promise.all(
      Object.keys(sameMaterialId).map((materialId: any) =>
        callCheckWallThickness(materialId, sameMaterialId[materialId])
      )
    )
    const rs2 = flattenData(rs.flat())
    const hasNotProcessed = rs2.some((item) => {
      const modelId = Object.keys(item)[0]
      return item[modelId].status === "not_processed"
    })

    if (hasNotProcessed) {
      const notProcessedMaterial = rs2.filter((item) => {
        const modelId = Object.keys(item)[0]
        return item[modelId].status === "not_processed"
      })
      const productNotProcessed = product.filter((item) => {
        const materialId = item.material_id
        return notProcessedMaterial.some((e) => e[materialId])
      })
      setTimeout(async () => {
        await handleCheckWallThickness(productNotProcessed)
      }, 5000)
    } else {
      setIsProcessing(false)
    }
  }

  return { handleCheckWallThickness, isWallThickNessError, isProcessing }
}
