import RootContainer from "@/components/RootContainer/RootContainer"
import "./CheckoutPage.scss"
import OrderSummary from "./components/OrderSummary/OrderSummary"
import { Button, HeadingCollapse, Modal } from "@/components"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeliveryIcon,
  downloadIcon,
  OrderReviewIcon,
  PaymentIcon,
  ShippingIcon
} from "@/assets"
import { useEffect, useMemo, useRef, useState } from "react"
import { useBlocker, useNavigate, useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/store"
import {
  IQuoteStore,
  removeDonKeysCheckout,
  setClickableKeysCheckout,
  setDoneKeysCheckout,
  setQuoteShippingAndTaxPrice
} from "@/store/quote"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import DeliveryAddress from "./components/DeliveryAddress/DeliveryAddress"
import OrderReview from "./components/OrderReview/OrderReview"
import ShippingMethod from "./components/ShippingMethod/ShippingMethod"
import Payment from "./components/Payment/Payment"
import { IDeliveryForm } from "@/interfaces/delivery.interface"
import { ICheckoutForm, IPaymentForm } from "@/interfaces/checkout.interface"
import {
  ECollapseKey,
  EPaymentMethod,
  EShippingMethod
} from "@/enums/checkout.enum"
import { Collapse, ConfigProvider, RadioChangeEvent } from "antd"

import * as toast from "@/utils/Toast"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { ShippingRateResponse } from "@/services/apiDigifabster/shipping"
import { INotesForm } from "@/interfaces/notes.interface"
import {
  OrderDetailResponse,
  useCreatePriceStartupCostMutation,
  useLazyGetDetailQuery,
  useLazyGetQuoteDetailQuery,
  useLazyGetQuoteQuery
} from "@/services/apiDigifabster/quote"
import {
  useLazyGetCompanyQuery,
  useLazyGetMeQuery,
  useLazyUpdateBillingAddressCompanyQuery
} from "@/services/apiDigifabster/user"
import ModalWarning from "./components/ModalWarning/ModalWarning"
import { useCheckoutFlow } from "@/hooks"
import { IAuthStore } from "@/store/auth"
import { IUserStore } from "@/store/user"
import {
  DATE_RANGE_FORMATS,
  DEFAULT_CANADA,
  DEFAULT_US,
  EMTY_STATE,
  METHOD_AND_NUMBER
} from "@/constants/common.constant"
import { getCountriesList, getStatesList } from "@/constants/countries"
import { IState } from "@/interfaces/common.interface"
import { convertToTitleCase } from "@/utils/functionHelper"
import { TIME_WITH_METHOD } from "@/constants/method-shipping.constant"
import { UserInfoResponse } from "@/store/user/type"
import {
  formatRangeDeliveryDate,
  getDiffDeliveryDays,
  getLatestDeliveryDate,
  getLatestLeadTimeForUser
} from "@/utils/timeFormatHelper"
import {
  useGetPaymentLinkMutation,
  useLazyGetInvoiceQuery
} from "@/services/apiDigifabster/order"
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import stripeConfig from "@/config/stripe"
import {
  ICheckoutFormNew,
  useUpdateIntentPaymentMutation
} from "@/services/apiDigifabster/payment"
import { getInvoiceHash } from "@/utils/stringHelper"
import { IBillingFormValues } from "@/interfaces/billing.interface"
import { FormProvider, useForm } from "react-hook-form"
import { BILLING_INFORMATION_SCHEMA } from "@/validations/billing.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { EModelAnalysisStatus, IProductStore } from "@/store/product"
import Notes from "./components/Notes/Notes"
import ManualReviewSection from "./components/ManualReviewSection/ManualReviewSection"

export default function CheckoutPage(): JSX.Element {
  const stripePromise = loadStripe(stripeConfig.apiKey || "")
  const [getPaymentLink, { isLoading }] = useGetPaymentLinkMutation()
  const { quoteId } = useParams()

  const [options, setOptions] = useState({ clientSecret: "" })

  useAppLoading([isLoading])
  useEffect(() => {
    onGetPaymentLink()
  }, [])

  const onGetPaymentLink = async () => {
    const { data, error } = await getPaymentLink({
      orderId: Number(quoteId)
    })
    if (!data || error) return setOptions({ clientSecret: "error" })
    setOptions({ clientSecret: data?.clientSecret })
  }
  return (
    <>
      {options?.clientSecret === "error" ? (
        <ManualReviewSection></ManualReviewSection>
      ) : (
        <>
          {options?.clientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutPageContent clientSecret={options?.clientSecret} />
            </Elements>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}

function CheckoutPageContent({
  clientSecret
}: {
  clientSecret: string
}): JSX.Element {
  const {
    currentQuote,
    doneKeysCheckout,
    clickableKeysCheckout,
    quoteDetail,
    modelsAnalysis
  } = useSelector<RootState, IQuoteStore & IProductStore>((s) => ({
    ...s.quote,
    ...s.product
  }))
  const { settings } = useSelector((state: RootState) => state.user)
  const { isLogOut } = useSelector<RootState, IAuthStore>((state) => state.auth)
  let { quoteId, invoice_hash, invoiceId } = useParams()
  const [getQuote, { isError }] = useLazyGetQuoteQuery()
  const [getOrderDetail] = useLazyGetQuoteDetailQuery()
  const [getDetail] = useLazyGetDetailQuery()
  const [getCompany] = useLazyGetCompanyQuery()

  const [getMe] = useLazyGetMeQuery()
  const [updatePaymentIntent] = useUpdateIntentPaymentMutation()
  const [isLoadingStripe, setIsLoadingStripe] = useState<boolean>(false)
  const [getInvoiceDetail] = useLazyGetInvoiceQuery()

  const [
    updateBillingAddressCompany,
    { isLoading: isUploadingNewBillingCompany }
  ] = useLazyUpdateBillingAddressCompanyQuery()
  const [createPriceStartupCost] = useCreatePriceStartupCostMutation()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoadingInit, setIsLoadingInit] = useState(false)
  const [isPrefilling, setIsPrefilling] = useState(false)
  const {
    sumbitOrder,
    sumbitQuote,
    updatePO,
    getShippingRateAndTax,
    beforeUnloadHandler,
    redirectInit,
    backFunction,
    disabledSubmit,
    goToPaymentPage,
    isLoadingSubmitOrder,
    isLoadingUpdateInvoice,
    getShippingRateLoading,
    getTaxRateLoading,
    getPaymentLinkLoading,
    isLoadingSubmitQuote
  } = useCheckoutFlow()

  useAppLoading([
    isLoadingUpdateInvoice,
    isLoadingSubmitOrder,
    isLoadingInit,
    getPaymentLinkLoading,
    isLoadingStripe,
    isLoadingSubmitQuote,
    isUploadingNewBillingCompany
  ])
  const [activeKey, setActiveKey] = useState<number>(1)
  const [formData, setFormData] = useState<ICheckoutForm>({} as ICheckoutForm)
  const [shippingMethod, setShippingMethod] = useState(EShippingMethod.DELIVERY)
  const [isEditDelivery, setIsEditDelivery] = useState<boolean>(true)
  const [removedKey, setRemovedKey] = useState<ECollapseKey>()
  const [paymentMethod, setPaymentMethod] = useState<string>(
    EPaymentMethod.CREDIT_CARD
  )
  const [shippingRate, setShippingRate] = useState<ShippingRateResponse[]>([])
  const [notes, setNotes] = useState<string | undefined>("")
  const [taxRate, setTaxRate] = useState<number | undefined>()
  const dispatch = useDispatch()
  const isRequiresReview = useMemo(() => {
    return Boolean(
      currentQuote?.available_order_initial_statuses?.includes(
        EOrderStatus.WAITING_FOR_REVIEW
      ) ||
        quoteDetail?.line_items?.some(
          (item) => item?.status === EOrderStatus.WAITING_FOR_REVIEW
        ) ||
        quoteDetail?.line_items?.some(
          (item) =>
            modelsAnalysis[item?.product?.id]?.status ===
            EModelAnalysisStatus.ERROR
        )
    )
  }, [currentQuote, quoteDetail, modelsAnalysis])
  const [confirmModal, setConfirmModal] = useState(false)
  const navigate = useNavigate()
  const { userInfoNew, company: billingCompany } = useSelector<
    RootState,
    IUserStore
  >((s) => s.user)
  const stripe = useStripe()
  const elements = useElements()
  const [stripeError, setStripeError] = useState<boolean>(true)
  const country = userInfoNew?.country
  const company_billing_address = billingCompany.billing_address
  const last_billing_address = userInfoNew?.last_billing_info
  const DEFAULT_COUNTRY = country === "US" ? DEFAULT_US : DEFAULT_CANADA

  const defaultBillingFormValues: IBillingFormValues = {
    firstName:
      formData?.billing?.firstName ||
      company_billing_address?.name ||
      last_billing_address?.name ||
      "",
    lastName:
      formData?.billing?.lastName ||
      company_billing_address?.surname ||
      last_billing_address?.surname ||
      "",
    phoneNumber:
      formData?.billing?.phoneNumber ||
      company_billing_address?.phone ||
      last_billing_address?.phone ||
      "",
    accountPayEmail:
      formData?.billing?.accountPayEmail ||
      company_billing_address?.email ||
      last_billing_address?.email ||
      "",
    state: formData?.billing?.state || EMTY_STATE,
    country: formData?.billing?.country || DEFAULT_COUNTRY,
    addressLine1:
      formData?.billing?.addressLine1 ||
      company_billing_address?.street_address ||
      last_billing_address?.street_address ||
      "",
    addressLine2:
      formData?.billing?.addressLine2 !== undefined
        ? formData?.billing?.addressLine2
        : company_billing_address?.apartment || last_billing_address?.apartment,
    company:
      formData?.billing?.company !== undefined
        ? formData?.billing?.company
        : company_billing_address?.company_name ||
          last_billing_address?.company_name ||
          "",
    city:
      formData?.billing?.city ||
      company_billing_address?.city ||
      last_billing_address?.city ||
      "",
    zipCode:
      formData?.billing?.zipCode ||
      company_billing_address?.postcode ||
      last_billing_address?.postcode ||
      "",
    taxNumber:
      formData?.billing?.taxNumber ||
      company_billing_address?.vat_number ||
      last_billing_address?.vat_number ||
      "",
    poNumber:
      formData?.po_number ||
      company_billing_address?.po_number ||
      last_billing_address?.po_number ||
      "",
    poFile: formData?.attached_po ? [formData?.attached_po] : []
  }
  const methods = useForm<IBillingFormValues>({
    resolver: yupResolver(BILLING_INFORMATION_SCHEMA),
    defaultValues: defaultBillingFormValues
  })
  const { reset } = methods

  useEffect(() => {
    if (formData) {
      reset(defaultBillingFormValues)
    }
  }, [formData])

  useEffect(() => {
    if (quoteId) {
      getData(quoteId)
      dispatch(setClickableKeysCheckout([1]))
      dispatch(setDoneKeysCheckout([]))
    }
  }, [quoteId])

  const handlePrefillData = async (
    meData?: UserInfoResponse,
    quoteData?: OrderDetailResponse
  ) => {
    const isPrefill =
      (meData?.last_shipping_method &&
        Object.keys(meData?.last_shipping_method)?.length > 0) ||
      (meData?.last_delivery_info &&
        Object.keys(meData?.last_delivery_info)?.length > 0) ||
      quoteData?.shipping_address ||
      quoteData?.self_notes ||
      quoteData?.name ||
      quoteData?.surname ||
      quoteData?.phone ||
      quoteData?.email ||
      quoteData?.additional_billing_contacts ||
      quoteData?.delivery_price

    setIsPrefilling(true)

    if (isPrefill) {
      const shipping_address = quoteData?.shipping_address
      const prefillCountry = await findCountry(
        shipping_address?.country || meData?.last_delivery_info?.country
      )
      const prefillSate = await findState(
        shipping_address?.state || meData?.last_delivery_info?.state,
        Number(prefillCountry.id)
      )
      const meMethod =
        meData?.last_shipping_method?.method?.toLowerCase() === "pick up"
          ? "pick_up"
          : meData?.last_shipping_method?.method?.toLowerCase()
      const quoteMethod =
        quoteData?.delivery_title?.toLowerCase() === "pick up"
          ? "pick_up"
          : quoteData?.delivery_title?.toLowerCase()
      const method = shipping_address
        ? (quoteMethod as EShippingMethod)
        : (meMethod as EShippingMethod)
      setShippingMethod(method)

      const additionalEmail =
        quoteData?.additional_billing_contacts?.join(", ") ||
        meData?.last_delivery_info?.additional_email ||
        ""
      const mail = quoteData?.email || meData?.last_delivery_info?.email || ""
      const email = !!mail
        ? `${mail}${!!additionalEmail ? `, ${additionalEmail}` : ""}`
        : `${additionalEmail}`

      const delivery_address = {
        name: quoteData?.name || meData?.last_delivery_info?.name || "",
        surname:
          quoteData?.surname || meData?.last_delivery_info?.surname || "",
        phoneNumber:
          quoteData?.phone || meData?.last_delivery_info?.phone || "",
        email,
        city: shipping_address?.city || meData?.last_delivery_info?.city || "",
        country: prefillCountry,
        state: prefillSate,
        company:
          shipping_address?.company_name ||
          meData?.last_delivery_info?.company_name ||
          "",
        addressLine1:
          shipping_address?.street_address ||
          meData?.last_delivery_info?.street_address ||
          "",
        addressLine2:
          shipping_address?.apartment ||
          meData?.last_delivery_info?.apartment ||
          "",
        zipCode:
          shipping_address?.postcode ||
          meData?.last_delivery_info?.postcode ||
          ""
      }
      if (
        delivery_address.addressLine1 &&
        delivery_address.zipCode &&
        delivery_address.state &&
        delivery_address.city &&
        delivery_address.country
      ) {
        const shippingMethod =
          quoteData?.self_notes?.match(METHOD_AND_NUMBER) || []
        await onSubmitDelivery(
          delivery_address,
          false,
          method,
          quoteData?.country
        )
        setFormData({
          ...formData,
          delivery_address,
          shipping_method: method || "",
          shipping_carrier:
            shippingMethod[1] || meData?.last_shipping_method?.carrier,
          shipping_note:
            shippingMethod[2] || meData?.last_shipping_method?.account_number
        })
        // const isExistData = Boolean(quoteData?.delivery_price || quoteData?.tax)
        // const shippingPrice =
        //   quoteData?.delivery_price || meData?.last_shipping_method?.price || 0
        const taxRate =
          quoteData?.tax || meData?.last_shipping_method?.tax_rate || 0
        if (method !== EShippingMethod.DELIVERY) {
          dispatch(
            setQuoteShippingAndTaxPrice({
              shippingPrice: 0,
              taxRate
            })
          )
        }

        // if (!isExistData && (shippingPrice || taxRate)) {
        //   dispatch(setQuoteShippingAndTaxPrice({ shippingPrice, taxRate }))
        // }
        handleDoneStep()
      }
    }
    setIsPrefilling(false)
  }

  const getData = async (quoteId: string) => {
    setIsLoadingInit(true)
    const { data: meData } = await getMe()
    await getCompany()
    await createPriceStartupCost({
      order_id: Number(quoteId) || 0
    })

    await getQuote({ quoteId })
    const { data, error } = await getOrderDetail({ quoteId })
    setIsLoadingInit(false)
    if (error || !data) return
    await handlePrefillData(meData, data)
  }

  useEffect(() => {
    if (isSubmitting || isLogOut) return

    const handleBeforeUnload = beforeUnloadHandler(
      formData,
      isSubmitting,
      isLogOut
    )

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [formData, isSubmitting, isLogOut])

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (isSubmitting) return false
    if (
      Object.keys(formData).length > 0 &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      setConfirmModal(true)
      return true
    }
    return false
  })

  const handleCancel = () => {
    setConfirmModal(false)
    blocker.reset?.()
  }

  const handleOk = () => {
    setConfirmModal(false)
    blocker.proceed?.()
  }

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useEffect(() => {
    redirectInit(quoteDetail, invoiceId, invoice_hash)
  }, [quoteDetail])

  const handleChangeCollapse = (key: string | string[]) => {
    const currentKey = Array.isArray(key) ? Number(key[1]) : Number(key)
    const isDoneStep = clickableKeysCheckout.includes(currentKey)
    if (!isDoneStep) return
    setActiveKey(currentKey)
  }

  const onSubmitNotes = async (data: INotesForm) => {
    const isPrefill = Boolean(
      doneKeysCheckout.includes(ECollapseKey.ORDER_REVIEW) &&
        doneKeysCheckout.includes(ECollapseKey.DELIVERY_ADDRESS) &&
        doneKeysCheckout.includes(ECollapseKey.SHIPPING_METHOD)
    )
    if (isPrefill) {
      handlePassStep()
      return
    }
    handleNextStep()
  }

  const fillNotes = async (data: INotesForm) => {
    setNotes(data.notes)
  }

  const findCountry = async (country?: string) => {
    if (!country) return DEFAULT_COUNTRY
    const countries = await getCountriesList()

    const prefillCountry = countries.find((item) => item.iso2 === country)
    const mapprefillCountry = {
      name: prefillCountry?.title || DEFAULT_COUNTRY.name,
      id: prefillCountry?.id || DEFAULT_COUNTRY.id,
      iso2: prefillCountry?.iso2 || DEFAULT_COUNTRY.iso2
    }
    return mapprefillCountry as IState
  }

  const findState = async (state?: string, countryId?: number) => {
    if (!state || !countryId) return EMTY_STATE
    const states = await getStatesList(countryId)
    const prefillState = states.find((item) => item.iso2 === state)
    const mapprefillState = {
      name: prefillState?.title || "",
      id: prefillState?.id || "",
      iso2: prefillState?.iso2 || ""
    }
    return mapprefillState as IState
  }

  const handlePassStep = () => {
    isRequiresReview
      ? setActiveKey(ECollapseKey.SHIPPING_METHOD)
      : setActiveKey(ECollapseKey.PAYMENT)
    setRemovedKey(undefined)
  }

  const handleDoneStep = () => {
    const nextKey = [
      ECollapseKey.ORDER_REVIEW,
      ECollapseKey.DELIVERY_ADDRESS,
      ECollapseKey.SHIPPING_METHOD
    ]
    setRemovedKey(undefined)

    dispatch(
      setClickableKeysCheckout([
        ...clickableKeysCheckout,
        ECollapseKey.PAYMENT,
        ...nextKey
      ])
    )
    dispatch(setDoneKeysCheckout([...doneKeysCheckout, ...nextKey]))
  }

  const handleNextStep = () => {
    const nextKey = activeKey + 1
    setActiveKey(nextKey)
    setRemovedKey(undefined)
    dispatch(setClickableKeysCheckout([...clickableKeysCheckout, nextKey]))
    dispatch(setDoneKeysCheckout([...doneKeysCheckout, activeKey]))
  }

  const handlePrevStep = () => {
    const nextKey = activeKey - 1
    setActiveKey(nextKey)
  }

  useEffect(() => {
    if (removedKey) {
      dispatch(removeDonKeysCheckout(removedKey))
    }
  }, [removedKey, activeKey])

  const onSubmitDelivery = async (
    data?: IDeliveryForm,
    isNextStep: boolean = true,
    method: EShippingMethod = shippingMethod,
    country?: string
  ) => {
    if (!data) return
    const sameAddress =
      formData?.delivery_address?.city === data.city &&
      formData?.delivery_address?.state?.iso2 === data.state?.iso2 &&
      formData?.delivery_address?.zipCode === data.zipCode &&
      formData?.delivery_address?.country?.iso2 === data.country.iso2

    if (sameAddress) {
      if (isNextStep) {
        handleNextStep()
        setFormData({ ...formData, delivery_address: data })
      }
      setIsEditDelivery(false)
      return
    }

    try {
      isNextStep && handleNextStep()
      isNextStep && setFormData({ ...formData, delivery_address: data })
      setIsEditDelivery(false)
      const results = await getShippingRateAndTax(
        currentQuote?.id || 0,
        data,
        country
      )
      const shippingRes =
        results[0].status === "fulfilled" ? results[0].value : null
      const taxRes = results[1].status === "fulfilled" ? results[1].value : null

      if (shippingRes?.error || taxRes?.error) {
        if (shippingRes?.error) setShippingRate([])
        dispatch(
          setQuoteShippingAndTaxPrice({
            shippingPrice: 0,
            taxRate: 0
          })
        )
      }

      const shippingRateRes = shippingRes?.data
      const taxRateRes = taxRes?.data
      if (shippingRateRes) {
        const _shippingRateRes = shippingRateRes.map((item) => ({
          ...item,
          price:
            shippingRateRes[0]?.price +
            shippingRateRes[0]?.price * (taxRateRes?.tax || 0)
        }))
        setTaxRate(taxRateRes?.tax)
        setShippingRate(_shippingRateRes)
        const isPrefill =
          !!formData?.shipping_method || method !== EShippingMethod.DELIVERY
        if (!isPrefill) {
          dispatch(
            setQuoteShippingAndTaxPrice({
              shippingPrice: _shippingRateRes[0]?.price || 0,
              taxRate: taxRateRes?.tax
            })
          )
        } else {
          dispatch(
            setQuoteShippingAndTaxPrice({
              shippingPrice: 0,
              taxRate: taxRateRes?.tax
            })
          )
        }
      } else {
        dispatch(
          setQuoteShippingAndTaxPrice({
            shippingPrice: 0,
            taxRate: taxRateRes?.tax
          })
        )
      }
    } catch (_) {
      setShippingRate([])
      dispatch(
        setQuoteShippingAndTaxPrice({
          shippingPrice: 0,
          taxRate: 0
        })
      )
    }
  }

  const onSubmitPayment = (data?: IPaymentForm) => {
    if (data) {
      const { poFile, poNumber, billing } = data
      setFormData({
        ...formData,
        attached_po: poFile || ({} as any),
        billing,
        po_number: billing?.poNumber || ""
      })
    }
  }

  const onChangeMethod = (data: {
    method: EShippingMethod
    carrier?: string
    note?: string
  }) => {
    setShippingMethod(data.method)
    setFormData({
      ...formData,
      shipping_method: data.method,
      shipping_carrier: data.carrier,
      shipping_note: data.note
    })
    handleNextStep()
  }

  const handleChangePaymentMethod = (e: RadioChangeEvent) => {
    setRemovedKey(ECollapseKey.PAYMENT)
    setPaymentMethod(e.target.value)
  }

  const getInvoice = async () => {
    if (!stripeError) {
      setIsSubmitting(true)
      const { data } = await getDetail({
        quoteId: quoteId || ""
      })
      const checkNullThumb = data?.main_invoice === null
      const isPaid = data?.main_invoice?.is_paid

      const invoiceId = data?.main_invoice?.id
      const invoiceHash = getInvoiceHash(data?.main_invoice?.pay_url)
      if (checkNullThumb || !isPaid) {
        return new Promise((res, rej) => {
          setTimeout(() => {
            getInvoice().then(res).catch(rej)
          }, 1000)
        })
      }
      if (invoiceId && invoiceHash && isPaid) {
        await getInvoiceDetail({ id: invoiceId.toString(), hash: invoiceHash })
        setIsLoadingStripe(false)
        return navigate(`/orders/invoices/${invoiceId}/${invoiceHash}`)
      }
    }
  }
  useEffect(() => {
    if (isLoadingStripe) {
      getInvoice()
    }
  }, [stripeError, invoiceId, invoice_hash, isLoadingStripe])

  const onSubmit = async (data?: IPaymentForm) => {
    try {
      let payload = { ...formData }
      if (data) {
        const { poFile, billing } = data
        payload = {
          ...formData,
          attached_po: poFile || formData.attached_po || ({} as any),
          billing,
          po_number: billing?.poNumber || "",
          notes: !!notes ? notes : undefined
        }
      }
      onSubmitPayment()
      if (!currentQuote) return

      setIsSubmitting(true)

      const currentStatus = isRequiresReview
        ? EOrderStatus.WAITING_FOR_REVIEW
        : (currentQuote?.status as EOrderStatus) || EOrderStatus.INITIAL

      if (isRequiresReview || paymentMethod === EPaymentMethod.PURCHASE_ORDER) {
        const resSubmitOrder = await sumbitQuote(
          payload,
          currentStatus,
          currentQuote.id || 0,
          paymentMethod as EPaymentMethod
        )
        const errorMsg = (resSubmitOrder.error as any)?.data?.message || ""
        if (resSubmitOrder.error || !resSubmitOrder.data)
          throw new Error(errorMsg)
        const invoice_id = invoiceId
          ? Number(invoiceId)
          : Number(resSubmitOrder.data.invoiceId)
        const invoiceHash = invoice_hash
          ? invoice_hash
          : resSubmitOrder.data.invoiceHash

        if (paymentMethod === EPaymentMethod.PURCHASE_ORDER && data) {
          if (data.poFile && data.billing.poNumber) {
            await updatePO(
              invoice_id,
              invoiceHash,
              data.poFile,
              data.billing.poNumber
            )
          }
          // update billing address for company if user inputted billing address and company billing address is not exist
          if (
            data.billing &&
            (!billingCompany.billing_address ||
              Object.keys(billingCompany.billing_address).length === 0) &&
            userInfoNew?.organization?.id
          ) {
            const payload = {
              companyId: userInfoNew?.organization?.id,
              billing_address: {
                ...data.billing,
                poFile: undefined // no need to save poFile in billing address
              }
            }
            await updateBillingAddressCompany(payload)
            await getCompany()
          }
          return navigate(
            `/new-quote/checkout/${currentQuote?.id}/submitted/invoices/${invoice_id}/${invoiceHash}`
          )
        } else {
          navigate(`/quotes/invoices/${invoice_id}/${invoiceHash}`)
        }
      } else {
        setIsLoadingStripe(true)

        const payloadClientSecret = `${clientSecret?.split("_")[0]}_${
          clientSecret?.split("_")[1]
        }`
        const {
          delivery_address,
          shipping_method,
          shipping_carrier,
          shipping_note,
          notes
        } = payload
        const mappedPayload: ICheckoutFormNew = {
          payload: {
            name: delivery_address?.name,
            surname: delivery_address?.surname,
            phone: delivery_address?.phoneNumber,
            email: delivery_address?.email,
            notes: notes || "",
            currentStatus,
            shippingMethod: {
              method: shipping_method || "",
              carrier: shipping_carrier || "",
              note: shipping_note || ""
            },
            shippingAddress: {
              addressLine1: delivery_address?.addressLine1 || "",
              addressLine2: delivery_address?.addressLine2 || "",
              country: delivery_address?.country.iso2 || "",
              state: delivery_address?.state.iso2 || "",
              city: delivery_address?.city || "",
              postalCode: delivery_address?.zipCode || "",
              companyName: delivery_address?.company || ""
            },
            country: userInfoNew.country
          },
          userId: userInfoNew.id
        }
        const resSubmitOrder = await sumbitOrder(
          payload,
          currentStatus,
          currentQuote.id || 0,
          paymentMethod as EPaymentMethod
        )
        const errorMsg = (resSubmitOrder.error as any)?.data?.message || ""
        if (resSubmitOrder.error || !resSubmitOrder.data)
          throw new Error(errorMsg)

        const { error: errorUpdateStripe } = await updatePaymentIntent({
          quoteId: quoteDetail?.id || 0,
          clientSecret: payloadClientSecret,
          payloadOrder: mappedPayload
        })

        if (!stripe || !elements || !clientSecret || errorUpdateStripe) return
        elements.submit()

        const { error } = await stripe.confirmPayment({
          elements,
          redirect: "if_required"
        })
        if (!error) {
          setStripeError(false)
        } else {
          setIsLoadingStripe(false)
        }
      }
    } catch (err) {
      console.log("err", err)
      const message = err as any
      toast.showError(message.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const submitText = useMemo(() => {
    if (isRequiresReview) return "Submit for review"
    return "Place Order"
  }, [activeKey, paymentMethod, isRequiresReview])

  const disabled = useMemo(() => {
    const isActive = isRequiresReview
      ? false
      : activeKey !== ECollapseKey.PAYMENT
    return disabledSubmit(isRequiresReview, doneKeysCheckout) || isActive
  }, [activeKey, doneKeysCheckout, getShippingRateLoading, getTaxRateLoading])

  const deliverySubText = useMemo(() => {
    if (formData?.delivery_address) {
      return `${formData?.delivery_address?.addressLine1}, ${formData?.delivery_address?.city}, ${formData?.delivery_address?.zipCode}`
    }
    return "Enter the address where your order will be delivered."
  }, [formData])

  const shippingMethodSubText = useMemo(() => {
    if (formData?.shipping_method === EShippingMethod.LOCAL_PICKUP)
      return "In-Person Pick-up"
    if (formData?.shipping_method && formData?.shipping_carrier) {
      return convertToTitleCase(
        `${formData?.shipping_carrier} ${
          TIME_WITH_METHOD[
            formData?.shipping_carrier as keyof typeof TIME_WITH_METHOD
          ]
        }`
      )
    }
    return "Select your shipping method and delivery speed."
  }, [formData])

  const overviewSubText = useMemo(() => {
    if (doneKeysCheckout.includes(ECollapseKey.ORDER_REVIEW)) {
      return `Expected ${formatRangeDeliveryDate(
        getDiffDeliveryDays(getLatestLeadTimeForUser(quoteDetail?.line_items)),
        getLatestDeliveryDate(quoteDetail?.line_items),
        new Date(),
        {
          from: DATE_RANGE_FORMATS.shortWithComma,
          to: DATE_RANGE_FORMATS.shortWithComma
        }
      )}`
    }
    return "Review quantities and production timelines."
  }, [doneKeysCheckout])

  const isLoadingShippingTax = useMemo(() => {
    return getShippingRateLoading || getTaxRateLoading || isPrefilling
  }, [isPrefilling, getShippingRateLoading, getTaxRateLoading])

  if (isLoadingInit) return <></>

  const itemSections = [
    {
      key: ECollapseKey.ORDER_REVIEW,
      label: (
        <>
          <HeadingCollapse
            title="Order Overview"
            prefix={"1."}
            subtitle={overviewSubText}
            isDone={doneKeysCheckout.includes(ECollapseKey.ORDER_REVIEW)}
          />
        </>
      ),
      children: <OrderReview onSubmit={onSubmitNotes} />
    },
    {
      key: ECollapseKey.DELIVERY_ADDRESS,
      label: (
        <>
          <HeadingCollapse
            title="Delivery Address"
            prefix={"2."}
            subtitle={deliverySubText}
            isLoading={isPrefilling}
            isDone={doneKeysCheckout.includes(ECollapseKey.DELIVERY_ADDRESS)}
          />
        </>
      ),
      children: (
        <DeliveryAddress
          onSubmit={onSubmitDelivery}
          prefillData={formData.delivery_address}
          isEdit={isEditDelivery}
          handlePreStep={handlePrevStep}
          setRemovedKey={setRemovedKey}
        />
      )
    },
    {
      key: ECollapseKey.SHIPPING_METHOD,
      label: (
        <>
          <HeadingCollapse
            title="Shipping Method"
            isLoading={isPrefilling}
            prefix={"3."}
            subtitle={shippingMethodSubText}
            isDone={doneKeysCheckout.includes(ECollapseKey.SHIPPING_METHOD)}
          />
        </>
      ),
      children: (
        <ShippingMethod
          state={formData?.delivery_address?.state?.iso2}
          shippingRate={shippingRate}
          taxRate={taxRate} // give tax rate to shipping method, prevent undefined tax rate
          handlePreStep={handlePrevStep}
          onChange={onChangeMethod}
          setRemovedKey={setRemovedKey}
          isLoading={isLoadingShippingTax}
          prefillMethod={formData}
        />
      )
    },
    {
      key: ECollapseKey.PAYMENT,
      label: (
        <>
          <HeadingCollapse
            title="Payment"
            prefix={"4."}
            subtitle="Select your payment method to complete the purchase."
            isDone={doneKeysCheckout.includes(ECollapseKey.PAYMENT)}
          />
        </>
      ),
      children: (
        <Payment
          onSubmit={onSubmit}
          disabled={disabled}
          submitText={submitText}
          prefillData={formData}
          value={paymentMethod}
          handleChangePaymentMethod={handleChangePaymentMethod}
          handlePreStep={handlePrevStep}
          setRemovedKey={setRemovedKey}
        />
      )
    }
  ]

  const itemsRequires = itemSections.filter(
    (item) => item.key !== ECollapseKey.PAYMENT
  )

  const handleBack = () => {
    backFunction(currentQuote, invoice_hash, invoiceId)
  }

  return (
    <FormProvider {...methods}>
      <RootContainer
        onBack={handleBack}
        headerText="Checkout"
        subHeaderText={`${currentQuote?.id ? `Q-${currentQuote?.id}` : ""}`}
        customRightElement={
          <div className="checkout-right-footer">
            <div className="checkout-question">
              Have Questions?{" "}
              <a href={`mailto:${settings.support_email}`}>Ask for help</a>
            </div>
            <div className="btn-download-box">
              <Button
                customSize={Size.MEDIUM}
                hierarchy={ButtonHierarchy.OUTLINE}
                customType={Type.NEUTRAL}
                onClick={() => {
                  window.open(currentQuote?.pdf_url || "", "_blank")
                }}
              >
                <img src={downloadIcon} alt="" />
                Download Draft Quote
              </Button>
            </div>
          </div>
        }
      >
        {confirmModal && (
          <ModalWarning
            openModal={confirmModal}
            closeModal={handleCancel}
            onLeave={handleOk}
          ></ModalWarning>
        )}
        <div className="checkout-container">
          <div className="checkout-left">
            <div className="checkout-review-container">
              <div className="checkout-review">
                <ConfigProvider
                  theme={{
                    token: {
                      fontFamily: '"Open Sans", sans-serif'
                    }
                  }}
                >
                  <Collapse
                    ghost
                    items={isRequiresReview ? itemsRequires : itemSections}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <>
                          <img
                            src={ChevronUpIcon}
                            height={20}
                            width={20}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={ChevronDownIcon}
                            height={20}
                            width={20}
                            alt=""
                          />
                        </>
                      )
                    }
                    onChange={handleChangeCollapse}
                    activeKey={activeKey}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="checkout-right">
            <OrderSummary
              paymentMethod={paymentMethod}
              orderData={formData}
              isLoading={isLoadingShippingTax}
              onSubmit={onSubmit}
              disabled={disabled}
              isRequiresReview={isRequiresReview}
              submitText={submitText}
              handleBack={handleBack}
              shippingMethodSubText={shippingMethodSubText}
            />
            <Notes onSubmit={fillNotes} />
          </div>
        </div>
      </RootContainer>
    </FormProvider>
  )
}
