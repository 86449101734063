import { useGetSuitableMaterialsMutation } from "@/services/apiDigifabster/model"
import { RootState } from "@/store"
import { IProductStore } from "@/store/product"
import { IQuoteStore } from "@/store/quote"
import { chunkArray } from "@/utils/functionHelper"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useCheckWallThickness } from "./useCheckWallThickness"
import { Product } from "@/services/apiDigifabster/quote"

export const useSuitableMaterials = () => {
  const { technologies, suitableMaterial } = useSelector<
    RootState,
    IProductStore
  >((s) => s.product)
  const { currentQuote, quoteDetail } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )

  const [getSuitableMaterials, { isLoading }] =
    useGetSuitableMaterialsMutation()
  const { handleCheckWallThickness } = useCheckWallThickness()

  useEffect(() => {
    if (!technologies.length || !currentQuote || !currentQuote.products) return

    const modelIds = currentQuote.products
      .map((e) => e.parent_model_id || e.model_id)
      .filter((e) => !suitableMaterial[e])
    const chuckedModelIds = chunkArray(modelIds, 10)
    handleGetWallThickness(currentQuote.products, chuckedModelIds)
  }, [currentQuote, technologies])

  const handleGetWallThickness = async (
    product: Product[],
    chuckedModelIds: any[][]
  ) => {
    await Promise.all(chuckedModelIds.map((e) => getSuitableMaterials(e)))
    await handleCheckWallThickness(product)
  }

  return isLoading
}
