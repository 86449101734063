import "./EditSpecificationDrawer.scss"
import "./EditSpecificationForm.scss"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/store"
import { Controller, useForm } from "react-hook-form"
import {
  IEditSpecificationsForm,
  IPostProductionData
} from "@/interfaces/editSpecifications.interface"
import { BadgeColor, BadgeType, Size, Type } from "@/enums/common.enum"
import {
  FormField,
  SelectDropdown,
  NumberField,
  Button,
  UploadFiles,
  CustomBadge,
  Loading
} from "@/components"
import { IQuoteStore, setEditProduct } from "@/store/quote"
import {
  EModelAnalysisStatus,
  IColor,
  ILeadTime,
  IMaterial,
  IMaterialConfig,
  IModelAnalysis,
  IProductStore,
  ITechnology
} from "@/store/product"
import { createElement, useEffect, useMemo, useState } from "react"
import {
  Config,
  Product,
  UploadDrawingsResponse,
  useDeleteDrawingsMutation,
  useGetPricePostProductionMutation,
  useGetPriceProductMutation,
  useUpdateProductMutation,
  useUploadDrawingsMutation
} from "@/services/apiDigifabster/quote"
import { useAppLoading } from "@/hooks/useLoading"
import { EDrawings } from "@/enums/createQuote.enum"
import * as toast from "@/utils/Toast"
import { UploadFile } from "antd"
import { debounce, priceDisplay } from "@/utils/functionHelper"
import { RcFile } from "antd/es/upload"
import { TOLERANCE_TEXT } from "@/constants/editSpecifications.constant"
import { errorStatus } from "@/constants/common.constant"
import { useCurrency } from "@/hooks/useCurrency"
import { IUserStore } from "@/store/user"
import { replacePostProductionTitle } from "@/utils/stringHelper"
import PostProductionDropdown from "./components/PostProductionDropdown/PostProductionDropdown"
import { PDFIcon } from "@/assets"
import { CloseOutlined } from "@ant-design/icons"
import { MANUAL_REQUEST_MATERIAL_IDS } from "@/constants/order.constrant"
import { EOrderStatus } from "@/enums/quotesList.enum"
interface IEditSpecificationDrawerProps {
  onUpdateDone?: () => void
  openEditDrawer?: boolean
}

interface IPrefix {
  technology: string
  color: string
}

export interface IEditSpecification {
  postProduction?: IPostProductionData[] | undefined
  postProductionCount?: number | undefined
  material: number
  filling: string
  color: string
  manufacturingProcess: number
  layerHeight: string
  quantity: number
  leadTime: string
}
const EditSpecificationDrawer: React.FC<IEditSpecificationDrawerProps> = ({
  openEditDrawer,
  onUpdateDone
}) => {
  const { suitableMaterial, technologies } = useSelector<
    RootState,
    IProductStore
  >((s) => s.product)
  const {
    currentQuote,
    quoteDetail,
    selectedProduct: product,
    currentPriceProduct
  } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const modelAnalysis = useSelector<RootState, IModelAnalysis | undefined>(
    (s) => s.product.modelsAnalysis[product?.model_id || 0]
  )
  const modelAnalysisIncludeReview = useMemo(() => {
    const findProduct = quoteDetail?.line_items?.find(
      (e) => e.id === product?.id
    )
    return findProduct?.status === EOrderStatus.WAITING_FOR_REVIEW
      ? findProduct?.self_notes?.includes("placeholder file")
        ? { ...modelAnalysis, status: EModelAnalysisStatus.REVIEW }
        : modelAnalysis
      : modelAnalysis
  }, [modelAnalysis, product, quoteDetail, openEditDrawer])
  const country = userInfoNew?.country || ""
  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors }
  } = useForm<IEditSpecification>({
    defaultValues: {}
    // resolver: yupResolver(EDIT_SPECIFICATIONS_SCHEMA)
  })
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [allTechnologies, setAllTechnologies] = useState<ITechnology[]>([])
  const [materials, setMaterials] = useState<IMaterial[]>([])
  const [colors, setColors] = useState<IColor[]>([])
  const [layerHeights, setLayerHeights] = useState<IMaterialConfig[]>([])
  const [inFills, setInFills] = useState<IMaterialConfig[]>([])
  const [postProduction, setPostProduction] = useState<
    Record<string, IMaterialConfig[]>
  >({} as Record<string, IMaterialConfig[]>)
  const [leadTimes, setLeadTimes] = useState<ILeadTime[]>([])
  const [currentPrefix, setCurrentPrefix] = useState<IPrefix>({
    technology: "",
    color: ""
  })
  const [disable, setIsDisable] = useState<boolean>(false)

  const [updateProduct, { isLoading, isSuccess, isError }] =
    useUpdateProductMutation()
  const [uploadDrawings, { isLoading: isUploadingFile }] =
    useUploadDrawingsMutation()
  const [deleteDrawings, { isLoading: isDeleteFile }] =
    useDeleteDrawingsMutation()
  const [getPriceProduct] = useGetPriceProductMutation()
  const [getPricePostProduction] = useGetPricePostProductionMutation()

  useAppLoading([isLoading])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoading && isSuccess && onUpdateDone) {
      onUpdateDone()
      reset()
    }
  }, [isSuccess])

  const watchTech = watch("manufacturingProcess")
  const watchMaterial = watch("material")
  const watchColor = watch("color")
  const watchQuanity = watch("quantity")
  const watchLayerHeight = watch("layerHeight")
  const watchFiling = watch("filling")
  const watchPostProduction = watch("postProduction")
  const watchLeadTime = watch("leadTime")

  const currentMaterial = useMemo(() => {
    return materials.find((e) => e.id === watchMaterial)
  }, [watchMaterial])

  useEffect(() => {
    if (!isLoading && isError) {
      toast.showError("Failed to update product")
    }
  }, [isError])
  const technologyItemOptionHeight = 70
  const { currency } = useCurrency()

  useEffect(() => {
    if (
      !watchMaterial ||
      !watchLayerHeight ||
      !watchColor ||
      !watchLeadTime ||
      !watchFiling ||
      !watchQuanity ||
      !product
    )
      return
    const config:
      | Record<string, string | string>
      | Record<string, { uuid: string }[]> = {}
    config["layer_thickness"] = watchLayerHeight
    config["filling"] = watchFiling
    if (watchColor) config["color"] = watchColor
    if (watchPostProduction)
      config["post_production"] = watchPostProduction?.map((e) => {
        return { uuid: e.uuid, quantity: e.quantity }
      })
    debouncedPrice(config, product)
  }, [
    watchQuanity,
    watchLayerHeight,
    watchFiling,
    watchColor,
    watchLeadTime,
    watchPostProduction
  ])
  const debouncedPrice = debounce(async (config, product) => {
    setIsPriceLoading(true)
    await getPriceProduct({
      config,
      model_id: product.model_id,
      material_id: watchMaterial,
      count: [watchQuanity],
      lead_time: [watchLeadTime]
    })
    setIsPriceLoading(false)
  }, 500)
  useEffect(() => {
    const currentColor = colors.find((e) => e.uuid === watchColor)
    setCurrentPrefix((prev) => ({
      ...prev,
      color: currentColor?.color || ""
    }))
  }, [watchColor, colors])

  const getTextPlaceHolder = (item: string) => {
    if (item === "Post Production") return "Standard Finish"
    if (item === "Inspection Criteria") return "Visual Inspection"
    return item
  }

  const resetWatchMaterial = () => {
    setMaterials([])
    setColors([])
    setLayerHeights([])
    setInFills([])
    setLeadTimes([])
    setPostProduction({})
    setValue("material", 0)
    setValue("color", "")
    setValue("layerHeight", "")
    setValue("filling", "")
    setValue("leadTime", "")
    setValue("postProduction", [])
    setValue("quantity", 1)
  }

  useEffect(() => {
    if (!watchTech || !product) return

    const tech = technologies.find((e) => e.id === watchTech)
    setCurrentPrefix((prev) => ({ ...prev, technology: tech?.image || "" }))
    const suitMaterials =
      suitableMaterial[product.parent_model_id]?.find((e) => e.id === watchTech)
        ?.materials || []
    const mappedMeterials =
      suitMaterials.map((e) => {
        return tech?.materials?.find((m) => m.id === e) || ({} as IMaterial)
      }) || []
    if (!tech) return

    // if (!mappedMeterials.length) {
    //   setMaterials(tech?.materials || []);
    //   setIsDisable(true)
    //   setError("manufacturingProcess", {
    //     message:
    //       "This part can’t be produced with this technology. Please try changing your model or choose a different technology"
    //   })
    //   return
    // }
    setError("manufacturingProcess", {})
    setMaterials(mappedMeterials)
    setIsDisable(false)
    const isPrefillMaterial = mappedMeterials?.some(
      (e) => e.id === product.material_id
    )
    const material = isPrefillMaterial
      ? product?.material_id
      : mappedMeterials[0]?.id
    setValue("material", material)
  }, [watchTech])

  useEffect(() => {
    if (!watchMaterial || !product) return

    const material = materials.find((e) => e.id === watchMaterial)
    if (!material) return

    const isProductMaterial = product?.material_id === material.id

    const colorList = material.color
    setColors(colorList || [])
    if (colorList && colorList.length) {
      const prefillColor = isProductMaterial
        ? product?.config?.color.uuid
        : colorList[0].uuid
      setValue("color", prefillColor)
    }
    const layerHeightList = material.layerThickness
    setLayerHeights(layerHeightList || [])
    if (layerHeightList && layerHeightList.length) {
      const prefilllayerHeight = isProductMaterial
        ? product?.config?.layer_thickness.uuid
        : layerHeightList[0].uuid
      setValue("layerHeight", prefilllayerHeight)
    }

    const leadTimeList = material.leadTime
    setLeadTimes(leadTimeList || [])
    if (leadTimeList && leadTimeList.length) {
      const prefillLeadTime = isProductMaterial
        ? product?.config?.lead_time.uuid
        : leadTimeList[0].uuid
      setValue("leadTime", prefillLeadTime)
    }

    const fillingList = material.filling
    setInFills(fillingList || [])
    if (fillingList && fillingList.length) {
      const prefillFilling = isProductMaterial
        ? product?.config?.filling.uuid
        : fillingList[0].uuid
      setValue("filling", prefillFilling)
    }

    if (material.post_production.length > 0) {
      fetchPostProduction(material, product)
    }

    const prefillQuantity = isProductMaterial ? product?.count : 1
    setValue("quantity", prefillQuantity)
  }, [watchMaterial])

  const fetchPostProduction = async (material: IMaterial, product: Product) => {
    const isProductMaterial = product?.material_id === material.id
    const { data: prices } = await getPricePostProduction({
      model_id: product.model_id,
      material_id: material?.id
    })
    if (!prices) return
    const postProductionData = material.post_production
      .filter((m) => {
        if (country === "CA") return !m.title.includes("-")
        return m.title.includes(`${country}`)
      })
      .map((e) => {
        const postProductionPrice = prices.items.find((p) => p.uuid === e.uuid)
        return {
          ...e,
          title: replacePostProductionTitle(e.title, country),
          price: postProductionPrice?.price_per_item || 0
        }
      })
    const postProductionList: Record<string, IMaterialConfig[]> =
      postProductionData.reduce((acc, item) => {
        const groupKey = item.group_title || "Post Production"
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(item)
        return acc
      }, {} as Record<string, IMaterialConfig[]>)
    setPostProduction(postProductionList)
    const prefillPostProduction = isProductMaterial
      ? product?.config?.post_production
      : []
    setValue("postProduction", prefillPostProduction)
  }
  useEffect(() => {
    reset()
    if (!product || !openEditDrawer) return

    const prefillFiles =
      (product.drawing_files as unknown as UploadFile[]) || []
    setFileList(prefillFiles)
    setAllTechnologies(technologies)

    const material = product.material_id
    const selectedTech = technologies.find((e) =>
      e.materials.find((m) => m.id === material)
    )
    if (!selectedTech) return

    setMaterials(selectedTech.materials)
    setValue("manufacturingProcess", selectedTech.id, {
      shouldDirty: false,
      shouldTouch: false
    })
    setCurrentPrefix((prev) => ({
      ...prev,
      technology: selectedTech?.image || ""
    }))

    // setValue("material", material, { shouldDirty: false })

    const selectedMaterial = selectedTech.materials.find(
      (e) => e.id === material
    )
    if (!selectedMaterial) return
    setColors(selectedMaterial?.color || [])
    setValue("color", product.config.color?.uuid, { shouldDirty: false })
    setLayerHeights(selectedMaterial?.layerThickness || [])
    setValue("layerHeight", product.config.layer_thickness?.uuid, {
      shouldDirty: false
    })

    setLeadTimes(selectedMaterial?.leadTime || [])
    setValue("leadTime", product.config.lead_time?.uuid, {
      shouldDirty: false
    })
    setValue("postProduction", product.config.post_production, {
      shouldDirty: false
    })
  }, [product, openEditDrawer])

  const onSubmit = (data: IEditSpecificationsForm) => {
    if (
      !currentQuote ||
      !currentQuote.id ||
      !product ||
      !product.id ||
      !quoteDetail ||
      !quoteDetail.id
    )
      return

    const config:
      | Record<string, string>
      | Record<string, IPostProductionData[]> = {}
    if (data.color) config["color"] = data.color
    if (data.layerHeight) config["layer_thickness"] = data.layerHeight
    if (data.filling) config["filling"] = data.filling
    if (data.postProduction) config["post_production"] = data.postProduction
    if (data.leadTime) config["lead_time"] = data.leadTime

    updateProduct({
      orderId: currentQuote.id,
      productId: product?.id,
      arg: {
        config,
        count: data.quantity,
        material_id: data.material,
        model_id: product.model_id,
        suitable_materials: materials
      }
    })
  }

  const isHidePrice = useMemo(() => {
    return (
      isPriceLoading ||
      disable ||
      ((modelAnalysisIncludeReview?.status === EModelAnalysisStatus?.ERROR ||
        modelAnalysisIncludeReview?.status === EModelAnalysisStatus?.REVIEW) &&
        currentPriceProduct?.errorAnalysisText) ||
      MANUAL_REQUEST_MATERIAL_IDS.includes(watchMaterial)
    )
  }, [
    isPriceLoading,
    disable,
    modelAnalysisIncludeReview,
    product,
    watchMaterial
  ])

  const isReviewProduct = useMemo(
    () => modelAnalysisIncludeReview?.status === EModelAnalysisStatus.REVIEW,
    [modelAnalysisIncludeReview]
  )
  const handleSubmitFile = async (file: UploadFile) => {
    try {
      const orderId = currentQuote?.id || 0

      const uploadPayload = new FormData()
      uploadPayload.append("file", file as RcFile, file.name)

      const res = await uploadDrawings({
        order_id: orderId,
        purchase_id: product?.id || 0,
        arg: uploadPayload
      })
      const modelsData = res?.data
      if (!modelsData) throw new Error()

      const resData = modelsData as unknown as UploadFile

      const fileObj: Record<string, UploadFile> = {
        [file.uid]: { ...resData, status: undefined, size: 0 }
      }
      setFileList((pre) => {
        return pre.map<UploadFile>((e) => fileObj[e.uid] || e)
      })
    } catch (_) {
      const fileObjFail: Record<string, UploadFile> = {
        [file.uid]: { ...file, status: "error", size: 0, name: file.name }
      }
      setFileList((pre) => {
        return pre.map<UploadFile>((e) => fileObjFail[e.uid] || e)
      })
    }
  }

  const debounceMultipleUpload = debounce((uploadFiles: UploadFile) => {
    handleSubmitFile(uploadFiles)
  }, 200)

  const handleUploadFiles = (info: {
    file: UploadFile
    fileList: UploadFile[]
  }) => {
    // fileList.map(async (file) => {
    //   const fileTrans = file as unknown as UploadDrawingsResponse

    //   await deleteDrawings({
    //     order_id: currentQuote?.id || 0,
    //     purchase_id: product?.id || 0,
    //     drawings_id: Number(fileTrans.id)
    //   })
    // })

    const uploadingFiles = info.fileList
      ?.filter((file, index) => {
        return index === info.fileList?.length - 1
      })
      .map((e) => {
        if (e.uid === info.file.uid) {
          return { ...e, status: "uploading", size: 0 } as UploadFile
        }
        return e
      })

    setFileList(uploadingFiles)
    debounceMultipleUpload(info.file)
  }

  const handleRemoveFile = async (file: UploadFile) => {
    if (file.uid && !file.url) {
      setFileList((pre) => pre.filter((e) => e.uid !== file.uid))
    } else {
      if (!currentQuote || !product) return
      const preTrans = fileList as unknown as UploadDrawingsResponse[]
      const fileTrans = file as unknown as UploadDrawingsResponse

      const data = await deleteDrawings({
        order_id: currentQuote?.id || 0,
        purchase_id: product?.id || 0,
        drawings_id: Number(fileTrans.id)
      })
      if (!data) return toast.showError("Failed to delete file")
      setFileList((prev) =>
        prev.filter(
          (e) => (e as unknown as UploadDrawingsResponse).id !== fileTrans.id
        )
      )
    }
  }

  const onChangePostProduction = (
    newPostProductionData: IPostProductionData[]
  ) => {
    setValue("postProduction", newPostProductionData)
  }

  if (!product || !currentQuote || !suitableMaterial[product?.parent_model_id])
    return <></>

  return (
    <form className="edit-specification-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-specification-form-section">
        <FormField
          clasName="edit-specification-form-label"
          textLabel="Manufacturing process"
          errorText={errors?.manufacturingProcess?.message}
        >
          <Controller
            name="manufacturingProcess"
            control={control}
            render={({ field: { ref, ...others } }) => (
              <div className="edit-specification-form-material-dropdown">
                <SelectDropdown
                  {...others}
                  width={"100%"}
                  listHeight={
                    allTechnologies.length * technologyItemOptionHeight
                  }
                  status={errorStatus(errors.manufacturingProcess)}
                  placeholder="Please select"
                  virtual={false}
                  dropdownClassName="edit-specification-form-dropdown"
                  prefixIcon={
                    <img
                      loading="lazy"
                      width={20}
                      height={20}
                      src={currentPrefix.technology}
                      alt=""
                    />
                  }
                  options={allTechnologies.map((e) => ({
                    id: e.id,
                    title: e.title,
                    subTitle: e.note,
                    prefix: createElement("img", {
                      src: e.image,
                      width: 20,
                      height: 20
                    })
                  }))}
                />
                <a href="#">Details</a>
              </div>
            )}
          />
        </FormField>

        <FormField
          clasName="edit-specification-form-label"
          textLabel="Material"
          errorText={errors?.material?.message}
        >
          <Controller
            name="material"
            control={control}
            render={({ field: { ref, ...others } }) => (
              <div className="edit-specification-form-material-dropdown">
                <SelectDropdown
                  {...others}
                  width={"100%"}
                  placeholder="Please select"
                  virtual={false}
                  dropdownClassName="edit-specification-form-dropdown"
                  prefixIcon={
                    <img
                      loading="lazy"
                      width={16}
                      height={16}
                      src={currentPrefix.technology}
                      alt=""
                    />
                  }
                  options={materials.map((e) => ({
                    id: e.id,
                    title: e.title,
                    prefix: createElement("img", {
                      src: currentPrefix.technology,
                      width: 16,
                      height: 16
                    })
                  }))}
                />
                {materials.find((e) => e.id === others.value)
                  ?.spec_sheet_url ? (
                  <a
                    href={
                      materials.find((e) => e.id === others.value)
                        ?.spec_sheet_url
                    }
                    target="_blank"
                  >
                    Spec sheet
                  </a>
                ) : (
                  <a className="disable">Spec sheet</a>
                )}
              </div>
            )}
          />
        </FormField>

        {colors.length ? (
          <FormField
            clasName="edit-specification-form-label"
            textLabel="Color"
            errorText={errors?.color?.message}
          >
            <Controller
              name="color"
              control={control}
              render={({ field: { ref, ...others } }) => (
                <SelectDropdown
                  {...others}
                  width={"100%"}
                  placeholder="Please select"
                  virtual={false}
                  dropdownClassName="edit-specification-form-dropdown"
                  prefixIcon={
                    <div
                      style={{
                        backgroundColor: currentPrefix.color,
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        border:
                          currentPrefix.color === "#FFFFFF"
                            ? "1px solid var(--border-gray-strong)"
                            : "none"
                      }}
                    />
                  }
                  options={colors.map((e) => ({
                    id: e.uuid,
                    title: e.title,
                    prefix: createElement("div", {
                      style: {
                        backgroundColor: e.color,
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        border:
                          e.color === "#FFFFFF"
                            ? "1px solid var(--border-gray-strong)"
                            : "none"
                      }
                    })
                  }))}
                  disabled={colors.length === 1}
                />
              )}
            />
          </FormField>
        ) : (
          <></>
        )}
        <div className="edit-specification-form-row">
          <FormField
            clasName="edit-specification-form-label"
            textLabel="Layer Height"
            errorText={errors?.layerHeight?.message}
          >
            <Controller
              name="layerHeight"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectDropdown
                  width={"100%"}
                  placeholder="Please select"
                  onChange={onChange}
                  value={value}
                  dropdownClassName="edit-specification-form-dropdown"
                  options={layerHeights.map((e) => ({
                    id: e.uuid,
                    title: e.title,
                    subTitle: e.note_for_user
                  }))}
                  disabled={layerHeights.length === 1}
                />
              )}
            />
          </FormField>
          {!!inFills.length && (
            <FormField
              clasName="edit-specification-form-label"
              textLabel="Infill"
              errorText={errors?.filling?.message}
            >
              <Controller
                name="filling"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    width={"100%"}
                    placeholder="Please select"
                    onChange={onChange}
                    value={value}
                    dropdownClassName="edit-specification-form-dropdown"
                    options={inFills.map((e) => ({
                      id: e.uuid,
                      title: e.title,
                      subTitle: e.note_for_user
                    }))}
                    disabled={inFills.length === 1}
                  />
                )}
              />
            </FormField>
          )}
        </div>

        <div className="edit-specification-form-row">
          <FormField
            clasName="edit-specification-form-label edit-specification-form-production-time"
            textLabel="Production Time"
            errorText={errors?.leadTime?.message}
          >
            <Controller
              name="leadTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectDropdown
                  width={"100%"}
                  placeholder="Please select"
                  onChange={onChange}
                  value={value}
                  options={leadTimes.map((e) => ({
                    id: e.uuid,
                    title: e.title,
                    subTitle: e.note_for_user
                  }))}
                  dropdownClassName="edit-specification-form-dropdown"
                  disabled={leadTimes.length === 1}
                />
              )}
            />
          </FormField>
        </div>

        <div className="edit-specification-form-row">
          <FormField
            clasName="edit-specification-form-label"
            textLabel="Quantity"
            errorText={errors?.quantity?.message}
          >
            <Controller
              name="quantity"
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumberField
                  disable={disable}
                  value={value}
                  setValue={onChange}
                  type={Type.NEUTRAL}
                  customClassName="edit-specification-form-quantity"
                  size={Size.EXTRA_SMALL}
                />
              )}
            />
          </FormField>
        </div>

        {!!currentMaterial?.post_production?.length && (
          <Controller
            name="postProduction"
            control={control}
            render={({ field: { value } }) => (
              <>
                {Object.keys(postProduction).map((item) => (
                  <div key={item}>
                    <FormField
                      clasName="edit-specification-form-label post-label"
                      textLabel={`${item}`}
                    >
                      <PostProductionDropdown
                        options={postProduction[item]}
                        value={value}
                        currency={currency}
                        placeholder={getTextPlaceHolder(item)}
                        onChangePostProduction={onChangePostProduction}
                      />
                      <div className="dropdown-learn-more">
                        Learn more about{" "}
                        <a
                          href={
                            item === "Post Production"
                              ? "https://forgelabs.com/post-processing-services/"
                              : ""
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            if (item !== "Post Production") {
                              e.preventDefault()
                            }
                          }}
                        >
                          {item.toLowerCase()}{" "}
                          {item === "Post Production" && "options"}
                        </a>
                      </div>
                    </FormField>
                  </div>
                ))}
              </>
            )}
          />
        )}
      </div>
      <div className="edit-specification-form-action">
        <Button
          htmlType="submit"
          customSize={Size.LARGE}
          customClassName="edit-specification-form-action-btn"
          disabled={isPriceLoading || isUploadingFile || isDeleteFile}
        >
          Apply
        </Button>
      </div>
      <div className="edit-specification-form-price">
        <div>
          <div className="unit-price">
            <span className="unit-price-title">Unit price</span>
            <span className="unit-price-value">
              {isHidePrice || isReviewProduct ? "" : currency}
              {isHidePrice
                ? "-.--"
                : currentPriceProduct?.price_per_part
                ? isReviewProduct
                  ? "-.--"
                  : priceDisplay(currentPriceProduct?.price_per_part || 0)
                : "-.--"}
            </span>
          </div>
          <div className="total-price">
            <span className="total-price-title">
              Total price{" "}
              {watchQuanity && watchQuanity > 0
                ? `(${watchQuanity} ${`${
                    watchQuanity > 1 ? "items" : "item"
                  }`})`
                : ""}
            </span>
            <span className="total-price-value">
              {isHidePrice || isReviewProduct ? "" : currency}
              {isHidePrice
                ? !isPriceLoading
                  ? "Requires Review"
                  : "-.--"
                : currentPriceProduct?.total_price
                ? isReviewProduct
                  ? "Requires Review"
                  : priceDisplay(currentPriceProduct?.total_price || 0)
                : isReviewProduct
                ? "Requires Review"
                : "-.--"}
            </span>
          </div>
        </div>
      </div>

      {/* {currentMaterial?.acceptDrawing === EDrawings.ACCEPT_NOT_REQUIRE && (
        <div className="edit-specification-form-upload">
          <UploadFiles
            listFiles={fileList}
            onUploadFiles={handleUploadFiles}
            uploadText="Upload Technical Drawings"
            onRemove={handleRemoveFile}
          />
        </div>
      )} */}
      {!!watchTech && (
        <div className="edit-specification-form-tolerances">
          <p className="edit-specification-form-tolerances-title">Tolerances</p>
          <p className="edit-specification-form-tolerances-subtitle">
            {TOLERANCE_TEXT[watchTech as keyof typeof TOLERANCE_TEXT]?.text}
            <span>
              <a
                href={
                  TOLERANCE_TEXT[watchTech as keyof typeof TOLERANCE_TEXT]?.url
                }
                target="_blank"
              >
                {" "}
                See full design guidelines{" "}
              </a>
              for details
            </span>
          </p>
        </div>
      )}

      {currentMaterial?.acceptDrawing === EDrawings.ACCEPT_NOT_REQUIRE && (
        <div className="edit-specification-form-upload">
          {fileList?.length === 0 && !isUploadingFile ? (
            <UploadFiles
              listFiles={fileList}
              onUploadFiles={handleUploadFiles}
              uploadText="Upload Engineering Drawings"
              onRemove={handleRemoveFile}
              isShowList={false}
            />
          ) : (
            <>
              <CustomBadge
                type={BadgeType.TONAL}
                color={BadgeColor.ROLE}
                size={Size.MEDIUM}
                content=""
              >
                <div
                  key={fileList[0].uid}
                  className="edit-specification-form-upload-render-file"
                >
                  <img src={PDFIcon} alt="" />
                  <p>{fileList[0].name}</p>
                  <div className="icon-box">
                    {isUploadingFile ? (
                      <Loading></Loading>
                    ) : (
                      <CloseOutlined
                        onClick={() => handleRemoveFile(fileList[0])}
                        className="icon-box-render"
                      />
                    )}
                  </div>
                </div>
              </CustomBadge>
              <p className="edit-specification-form-upload-text">
                The CAD file and specifications you select for this part will
                take precedence over technical drawings.
              </p>
            </>
          )}
        </div>
      )}

      {/* {currentMaterial?.acceptDrawing === EDrawings.ACCEPT_NOT_REQUIRE && (
        <div className="edit-specification-form-upload">
          {!!fileList?.length ? (
            <div className="edit-specification-form-uploadfile-list">
              {isUploadingFile ? <Loading></Loading> : (
                <>
                 <CustomBadge type={BadgeType.TONAL} color={BadgeColor.ROLE} size={Size.MEDIUM} content=''>
                  <div
                    key={fileList[0].uid}
                    className="item-render-pdf"
                  >
                    <img src={PDFIcon} alt="" />
                    <p>{fileList[0].name}</p>
                    <div className="icon-box">
                      <CloseOutlined
                        onClick={() =>
                          handleRemoveFile(fileList[0])
                        }
                        className="icon-box-render"
                      />
                    </div>
                  </div>
                </CustomBadge>
                 <p style={{ paddingTop: '16px' }}>The CAD file and specifications you select for this part will take precedence over technical drawings.</p>
                </>
              )}
            </div>
          ) : (
            <UploadFiles
              listFiles={fileList}
              hideListFile={true}
              onUploadFiles={handleUploadFiles}
              uploadText="Upload Technical Drawings"
              onRemove={handleRemoveFile}
            />
          )}
        </div>
      )} */}
    </form>
  )
}

export default EditSpecificationDrawer
