import { EModelAnalysisStatus } from "@/store/product"
import { Button, ButtonProps } from "antd"
import "./DFMButton.scss"

export enum DFMType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  DISABLE = "disable",
  REVIEW = ""
}

interface IDFMButtonProps extends ButtonProps {
  status?: EModelAnalysisStatus
  customType?: DFMType
}
const DFMButton: React.FC<IDFMButtonProps> = (props) => {
  const { customType, disabled, status, children, ...rest } = props
  const buttonTypes = {
    [DFMType.SUCCESS]: "button-success",
    [DFMType.WARNING]: "button-warning",
    [DFMType.ERROR]: "button-error",
    [DFMType.DISABLE]: "",
    [DFMType.REVIEW]: ""
  }

  const buttonStyles = buttonTypes[customType ?? DFMType.SUCCESS]
  return (
    <Button
      {...rest}
      disabled={status === EModelAnalysisStatus.LOADING}
      className={`${buttonStyles} button-dfm ${
        disabled ? "prevent-click" : ""
      }`}
    >
      {children}
    </Button>
  )
}

export default DFMButton
