import { Button, CustomBadge, RootContainer } from "@/components"
import "./OrderDetailPage.scss"
import {
  useLazyGetInvoiceQuery,
  useRepeatOrderMutation
} from "@/services/apiDigifabster/order"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { useAppLoading } from "@/hooks/useLoading"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { format } from "date-fns"
import { useCurrency } from "@/hooks/useCurrency"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size,
  Type
} from "@/enums/common.enum"
import { NotesIcon, orderForgeLabIcon, repeatIcon } from "@/assets"
import { EOrderStatus } from "@/enums/quotesList.enum"
import {
  useGetAdditionalFileQuery,
  useLazyGetQuoteDetailQuery
} from "@/services/apiDigifabster/quote"
import { IAuthStore } from "@/store/auth"
import {
  formatRangeDeliveryDate,
  getDiffDeliveryDays,
  getLatestDeliveryDate,
  getLatestLeadTimeForUser
} from "@/utils/timeFormatHelper"
import OrderDetailSummary from "./components/OrderDetailSummary/OrderDetailSummary"
import OrderDetailItem from "./components/OrderDetailItem/OrderDetailItem"
import { getTextShippingMethod, priceDisplay } from "@/utils/functionHelper"
import { EDeliveryCarrier, EDeliveryTime } from "@/enums/shipping.enums"
import { DATE_RANGE_FORMATS } from "@/constants/common.constant"

function OrderDetailPage() {
  const { invoiceId, invoice_hash } = useParams()
  const [getInvoiceDetail, { isFetching, isError, data }] =
    useLazyGetInvoiceQuery()
  const { invoiceDetail, quoteDetail } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )
  const [repeatOrder, { isLoading: isRepeatingOrderLoading }] =
    useRepeatOrderMutation()
  const [getOrderDetail, { isFetching: isFetchingOrder, data: orderData }] =
    useLazyGetQuoteDetailQuery()

  const [currency, setCurrency] = useState<string>("CA")

  const orderId = invoiceDetail?.order?.id
  const { data: fileData, isFetching: isFetchingFile } =
    useGetAdditionalFileQuery(
      { quoteId: String(invoiceDetail?.order?.id) },
      { skip: !orderId }
    )

  const { isAuthenciated } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const { pathname, search } = useLocation()
  const { getCurrency } = useCurrency()

  const navigate = useNavigate()

  useEffect(() => {
    if (invoiceId && invoice_hash) {
      initPage()
    }
  }, [])

  const initPage = async () => {
    if (invoiceId && invoice_hash) {
      const { data: res } = await getInvoiceDetail({
        id: invoiceId,
        hash: invoice_hash
      })
      const { data: detail } = await getOrderDetail({
        quoteId: String(res?.order?.id)
      })
      setCurrency(getCurrency(detail?.country) || "CA")

      if (
        [
          EOrderStatus.FIRM_OFFER_SENT,
          EOrderStatus.WAITING_FOR_REVIEW,
          EOrderStatus.CANCEL_BY_ADMIN,
          EOrderStatus.CANCEL_BY_USER
        ].includes(res?.order?.status as EOrderStatus)
      ) {
        navigate(`/quotes/invoices/${invoiceId}/${invoice_hash}`)
      }
    }
  }

  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useAppLoading([
    isFetching,
    isFetchingFile,
    isFetchingOrder,
    isRepeatingOrderLoading
  ])

  const product = invoiceDetail?.order?.line_items
  const order = invoiceDetail?.order
  const createdAt = order?.date
    ? format(new Date(order.date), "MMMM do, yyyy")
    : ""
  const additionalContact =
    order?.additional_billing_contacts?.length > 0
      ? order.additional_billing_contacts?.[0]
      : ""

  const hideBillingAddress = useMemo(() => {
    return (
      order?.billing_address?.company_name ||
      order?.billing_address?.street_address ||
      order?.billing_address?.apartment ||
      order?.billing_address?.city ||
      order?.billing_address?.state ||
      order?.billing_address?.country ||
      order?.billing_address?.postcode
    )
  }, [order?.billing_address])

  const handleBack = () => {
    if (pathname.includes("orders")) {
      const params = new URLSearchParams(search)
      const page = params.get("page")
      const limit = params.get("limit")

      return navigate(`/orders?page=${page}&limit=${limit}`)
    }
    return navigate(`/new-quote`)
  }

  if (!data || isFetching || isFetchingFile || isFetchingOrder || !orderData)
    return <></>

  const deliveryTypeText = (str: string) => {
    const convertStr = str ? str.replace(str[0], str[0].toUpperCase()) : ""
    if (str === EDeliveryCarrier.FEDEX)
      return `${convertStr} ${EDeliveryTime.FEDEX}`
    if (str === EDeliveryCarrier.UPS)
      return `${str.toUpperCase()} ${EDeliveryTime.UPS}`
    if (str === EDeliveryCarrier.PUROLATOR)
      return `${convertStr} ${EDeliveryTime.PUROLATOR}`
    return "Pick up"
  }

  const handleRepeatOrder = async () => {
    const res = await repeatOrder({
      orderId: invoiceDetail?.order?.id
    })

    if (res?.data?.id) {
      navigate(`/new-quote/specification/${res?.data?.id}`)
    }
  }

  return (
    <RootContainer
      onBack={isAuthenciated ? handleBack : undefined}
      headerText="Order Details"
      customRightElement={
        <Button
          onClick={handleRepeatOrder}
          customType={Type.NEUTRAL}
          hierarchy={ButtonHierarchy.OUTLINE}
        >
          <img src={repeatIcon} alt="" />
          Repeat Order
        </Button>
      }
    >
      <div className="order-detail">
        <div className="order-detail-left">
          <div className="order-detail-left-content">
            <div className="order-detail-left-content-detail">
              <div className="order-detail-left-content-detail-header">
                <div className="order-detail-left-content-detail-header-wrapper">
                  <div className="order-detail-left-content-detail-header-title">
                    <div className="order-detail-left-content-detail-header-img">
                      <img src={orderForgeLabIcon} alt="" />
                    </div>
                    <p>
                      Your order{" "}
                      <span>#{invoiceDetail?.order?.company_order_id}</span> has
                      been confirmed!
                    </p>
                    <p>
                      Check back here for the latest information on your
                      project.
                    </p>
                  </div>
                  <div className="order-detail-left-content-detail-header-info">
                    <p className="order-detail-left-content-detail-header-info-text-title">
                      ORDER DETAILS
                    </p>
                    <div className="order-detail-left-content-detail-header-info-detail">
                      <div className="order-detail-left-content-detail-header-info-detail-left">
                        <p>Order Status</p>
                        <CustomBadge
                          content={invoiceDetail?.order?.status_display}
                          type={BadgeType.TONAL}
                          size={Size.MEDIUM}
                          color={
                            invoiceDetail?.order?.status === EOrderStatus.PLACED
                              ? BadgeColor.SUCCESS
                              : BadgeColor.ROLE
                          }
                        />
                      </div>
                      <div className="order-detail-left-content-detail-header-info-detail-right">
                        <p>Date</p>
                        <p>{createdAt}</p>
                      </div>
                    </div>
                    <div className="order-detail-left-content-detail-header-info-detail">
                      <div className="order-detail-left-content-detail-header-info-detail-left">
                        <p>Delivery Type</p>
                        <p>
                          {deliveryTypeText(
                            getTextShippingMethod(
                              invoiceDetail?.order?.self_notes
                            )
                          )}
                        </p>
                      </div>
                      <div className="order-detail-left-content-detail-header-info-detail-right">
                        <p>Tracking Number</p>
                        <p>{invoiceDetail?.order?.tracking_number || "-"}</p>
                      </div>
                    </div>
                    <div className="order-detail-left-content-detail-header-info-detail">
                      <div className="order-detail-left-content-detail-header-info-detail-left">
                        <p>Total</p>
                        <p>
                          {currency}
                          {priceDisplay(invoiceDetail?.order?.total_price)}
                        </p>
                      </div>
                      <div className="order-detail-left-content-detail-header-info-detail-right">
                        <p>Estimated Completion</p>
                        <p className="order-detail-left-content-detail-header-info-detail-right-estimated">
                          {formatRangeDeliveryDate(
                            getDiffDeliveryDays(
                              getLatestLeadTimeForUser(
                                invoiceDetail?.order?.line_items
                              )
                            ),
                            getLatestDeliveryDate(
                              invoiceDetail?.order?.line_items
                            ),
                            new Date(invoiceDetail?.order?.date),
                            {
                              from: DATE_RANGE_FORMATS.shortWithComma,
                              to: DATE_RANGE_FORMATS.shortWithComma
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-detail-left-content-info">
                <div className="order-detail-left-content-info-address">
                  <div className="order-detail-left-content-info-address-shipping">
                    <p className="order-detail-left-content-info-address-title">
                      Shipping Address
                    </p>
                    <div className="order-detail-left-content-info-address-shipping-detail">
                      <p>
                        {order?.shipping_address?.company_name &&
                        order?.shipping_address?.company_name !== "blank"
                          ? order?.shipping_address?.company_name
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {order?.shipping_address?.street_address || ""}
                        {order?.shipping_address?.apartment &&
                          `, ${order?.shipping_address?.apartment || ""}`}
                      </p>
                      <p>
                        {order?.shipping_address?.city || ""}
                        {order?.shipping_address?.state &&
                          `, ${order?.shipping_address?.state || ""}`}
                      </p>
                      <p>
                        {order?.shipping_address?.country || ""}
                        {order?.shipping_address?.postcode &&
                          `, ${order?.shipping_address?.postcode || ""}`}
                      </p>
                    </div>
                  </div>
                  {hideBillingAddress && (
                    <div className="order-detail-left-content-info-address-billing">
                      <p className="order-detail-left-content-info-address-title">
                        Billing Address
                      </p>
                      <div className="order-detail-left-content-info-address-billing-detail">
                        {order?.billing_address?.company_name && (
                          <p>{order.billing_address.company_name}</p>
                        )}
                        {order?.billing_address?.street_address !== "none" &&
                          order?.billing_address?.street_address && (
                            <p>
                              {order.billing_address.street_address}
                              {order?.billing_address?.apartment &&
                                `, ${order.billing_address.apartment}`}
                            </p>
                          )}

                        {order?.billing_address?.city !== "none" &&
                          order?.billing_address?.city && (
                            <p>
                              {order.billing_address.city}
                              {order?.billing_address?.state &&
                              order.billing_address.state !== "none"
                                ? `, ${order.billing_address.state}`
                                : ""}
                            </p>
                          )}

                        {order?.billing_address?.country && (
                          <p>
                            {order.billing_address.country}
                            {order?.billing_address?.postcode &&
                            order.billing_address.postcode !== "none"
                              ? `, ${order.billing_address.postcode}`
                              : ""}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="order-detail-left-content-detail-product">
              <div className="order-detail-left-content-detail-product-header">
                <p>MODEL</p>
                <p>SPECIFICATIONS</p>
                <p>POST PRODUCTION</p>
                <p>QUANTITY</p>
                <p>TOTAL</p>
              </div>
              <div className="order-detail-left-content-detail-product-list">
                {product?.map((item) => (
                  <OrderDetailItem
                    currency={currency}
                    key={item.id}
                    item={item}
                    deliveryDays={getLatestDeliveryDate(
                      quoteDetail?.line_items
                    )}
                  />
                ))}
                {!!order?.price_corrections?.length && (
                  <div className="order-detail-left-content-detail-additional">
                    <div className="order-detail-left-content-detail-additional-service">
                      <p className="order-detail-left-content-detail-additional-service-title">
                        Additional Services
                      </p>
                      {order?.price_corrections?.map((item) => (
                        <div className="order-detail-left-content-detail-additional-service-detail">
                          <div className="order-detail-left-content-detail-additional-service-detail-info">
                            <p>{item.comment ? item.comment : "-"}</p>
                            <p>
                              + {currency}{" "}
                              {priceDisplay(Number(item.value) || 0)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {order?.notes && (
                  <div className="order-detail-left-content-note">
                    <div className="order-detail-left-content-note-header">
                      <img src={NotesIcon} alt="" />
                      <p>Add Order Notes</p>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${order?.notes}`
                      }}
                    ></div>
                  </div>
                )}
                {fileData?.length === 0 && (
                  <div className="order-detail-left-content-files">
                    {fileData?.map((item: any) => (
                      <div
                        key={item.id}
                        className="order-detail-left-content-files-item"
                      >
                        <p>{item.name}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="order-detail-right">
          <OrderDetailSummary currency={currency} />
        </div>
      </div>
    </RootContainer>
  )
}

export default OrderDetailPage
